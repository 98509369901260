<template xmlns="http://www.w3.org/1999/html">
  <div>
    <Breadcrumbs title="Účty"/>

    <!-- Add account modal -->
    <b-modal id="add-account-modal" size="lg" title="Přidat účet" ok-title="Přidat" cancel-title="Zrušit" class="theme-modal"
             @show="resetModal"
             @hidden="resetModal"
             @ok="addAccountOk"
    >
      <form class="theme-form" ref="newAccountForm" id="newAccountForm" @submit.stop.prevent="addAccount">
        <div class="form-group">
          <label for="username">Jméno:</label>
          <input
              type="text"
              v-model="newAccount.firstName"
              name="firstName"
              class="form-control"
              required
              :class="{ 'is-invalid': submitted && !newAccount.firstName }"
          />
        </div>
        <div class="form-group">
          <label for="username">Příjmení:</label>
          <input
              type="text"
              v-model="newAccount.lastName"
              name="lastName"
              class="form-control"
              required
              :class="{ 'is-invalid': submitted && !newAccount.lastName }"
          />
        </div>
        <div class="form-group">
          <label for="username">Uživatelské jméno:</label>
          <input
              type="text"
              v-model="newAccount.username"
              name="username"
              class="form-control"
              required
              :class="{ 'is-invalid': submitted && !newAccount.username }"
          />
        </div>
        <div class="form-group">
          <label for="username">E-mail:</label>
          <input
              type="email"
              v-model="newAccount.email"
              name="email"
              class="form-control"
              required
              :class="{ 'is-invalid': submitted && !newAccount.email }"
          />
        </div>
        <div class="form-group">
          <label for="username">Heslo:</label>
          <input
              type="password"
              v-model="newAccount.password"
              name="password"
              class="form-control"
              required
              :class="{ 'is-invalid': submitted && !newAccount.password }"
          />
        </div>
        <div class="form-group">
          <label for="username">Role:</label>
          <select
              v-model="newAccount.role"
              name="role"
              class="form-control"
              required
              :class="{ 'is-invalid': submitted && !newAccount.role }"
          >
            <option value="ADMIN">Administrátor</option>
            <option value="MANAGER">Manažer</option>
            <option value="DOCTOR">Doktor</option>
            <option value="HEAD_NURSE">Staniční sestra</option>
            <option value="NURSE">Sestra</option>
            <option value="RECEPTIONIST">Recepční</option>
          </select>
        </div>
      </form>
    </b-modal>



    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <!-- Accounts -->
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-6">
                  <h5>Účty</h5>
                </div>
                <div class="col-6 text-right">
                  <b-button v-b-modal.add-account-modal variant="primary">Přidat</b-button>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="text-center loading" v-if="loading">
                Nahrávám ...
              </div>

              <b-row v-if="!loading">
                <b-col xl="6">
                  <b-input-group class="datatable-btn">
                    <b-form-input v-model="filter" placeholder="Vyhledat"></b-form-input>
                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''">Vyčistit</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>

                <b-col xl="6">
                  <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <div class="table-responsive datatable-vue">
                <b-table
                    show-empty
                    stacked="md"
                    :items="accounts"
                    :fields="tableFields"
                    :filter="filter"
                    :current-page="currentPage"
                    :per-page="perPage"
                    @filtered="onFiltered"
                >
                  <template v-slot:cell(action)="{ item }">
                    <span><b-btn @click="detail(item.id)">Detail</b-btn></span>
                  </template>
                  <template v-slot:cell(createdAt)="{ item }">
                    <span>{{ format(item.createdAt) }}</span>
                  </template>
                </b-table>
              </div>

              <b-col md="6" class="my-1 p-0">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    class="my-0"
                ></b-pagination>
              </b-col>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import {userService} from "@/_services/user.service";
import {formatDateTime} from "@/_helpers";
import router from "@/router";

export default {
  data() {
    return {
      newAccount: {},
      submitted: false,
      accounts: [],
      loading: false,
      filter: null,

      tableFields: [
        { key: 'firstName', label: 'Jméno', sortable: true },
        { key: 'lastName', label: 'Příjmení', sortable: true },
        { key: 'role', label: 'Role', sortable: true },
        { key: 'active', label: 'Aktivní', sortable: true },
        { key: 'createdAt', label: 'Čas registrace', sortable: true },
        { key: 'action', label: 'Akce', sortable: false },
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, 50],
    }
  },
  methods: {
    checkFormValidity() {
      return this.$refs.newAccountForm.checkValidity()
    },
    format(date) {
      return formatDateTime(date)
    },
    resetModal() {
      this.submitted = false
      this.newAccount = {}
    },
    addAccountOk(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.addAccount()
    },
    addAccount(bvModalEvent) {
      this.submitted = true
      if (!this.checkFormValidity()) {
        return
      }

      userService.addAccount(this.newAccount).then(result => {
        this.getAccounts()
        this.$nextTick(() => {
          this.$bvModal.hide('add-account-modal')
        })
      }).catch( error =>
          alert("Nepodařilo se vytvořit uživatele: " + error)
      )

    },
    getAccounts() {
      userService.getAccounts().then(accounts => {
        this.accounts = accounts.data
      })
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    detail(id) {
      router.push("/dashboard/account-detail/" + id)
    }
  },
  created() {
    this.getAccounts()
  },
  addAccount() {

  }
}
</script>
