<template>
  <div>
    <Breadcrumbs title="Detail objednání"/>
    <!-- Container-fluid starts-->
    <div class="container-fluid">

      <div class="row">

        <div class="col-12" style="text-align: right;padding-bottom: 20px">
          <div v-if="loading === false && appointment.status === 'NEW'" role="group" class="btn-group">
            <button class="btn btn-primary" v-on:click="process">Zprocesovat</button>
          </div>
          <div v-if="loading === false && appointment.status === 'IN_PROGRESS'" role="group" class="btn-group">
            <button class="btn btn-dark-gradien" v-on:click="returnAppointment">Vrátit</button>
            <button class="btn btn-danger" v-on:click="canceled">Zamítnout</button>
            <button class="btn btn-primary" v-on:click="completed">Objednáno</button>
          </div>
          <div v-if="loading === false && (appointment.status === 'CANCELED' || appointment.status === 'COMPLETED')" role="group" class="btn-group">
            <button class="btn btn-warning" v-on:click="restoreAppointment">Obnovit objednání</button>
          </div>
        </div>

        <!-- Detail objednání -->
        <div class="col-md-6 col-xs-12">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-md-8"><h5>Objednání <span v-if="appointment">{{ appointment.firstName }} {{ appointment.lastName }}</span></h5> <span class="appointment-status" v-if="appointment">[{{ appointment.status }}]</span>
                </div>
                <div class="col-md-4" style="text-align: right">
                  <button class="btn btn-primary" v-on:click="getAppointment">Obnovit data</button>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="text-center loading" v-if="loading">
                Nahrávám ...
              </div>
              <table class="table" v-if="!loading">
                <tr>
                  <th>Jméno</th>
                  <td>{{ appointment.firstName }}</td>
                </tr>
                <tr>
                  <th>Příjmení</th>
                  <td>{{ appointment.lastName }}</td>
                </tr>
                <tr>
                  <th>Čas objednání</th>
                  <td>{{ formatDateTime(appointment.createdAt) }}</td>
                </tr>
                <tr>
                  <th>Rodné číslo</th>
                  <td>{{ appointment.birthNumber || '-' }}</td>
                </tr>
                <tr>
                  <th>E-mail</th>
                  <td>{{ appointment.email }}</td>
                </tr>
                <tr>
                  <th>Telefon</th>
                  <td>{{ appointment.phone }}</td>
                </tr>
                <tr>
                  <th>Preferované datum</th>
                  <td><strong>{{ formatPrefDate(appointment.preferredDate) }}</strong></td>
                </tr>
                <tr>
                  <th>Poznámka pacienta:</th>
                  <td>
                    {{ appointment.note || '-' }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <!-- KONEC detailu objednání -->

        <!-- Poznámky operátora -->
        <div class="col-md-6 col-xs-12">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-md-8"><h5>Poznámky operátora</h5></div>
                <div class="col-md-4" style="text-align: right">
                  <button class="btn btn-success" v-on:click="saveNote" v-if="noteChanged">Uložit</button>
                </div>
                <span v-if="noteChanged" style="padding-left: 15px; color: #a61c00">Poznámka byla upravena. Nezapomeťe ji uložit!</span>
                <span v-if="!noteChanged && noteSaved" style="padding-left: 15px; color: #00a603">Poznámka uložena</span>
                <span v-if="!noteChanged" style="padding-left: 15px"></span>
              </div>
            </div>
            <div class="card-body">
              <textarea v-if="!loading" v-on:input="operatorNoteChanged" v-model="appointment.operatorNote" name="operatorNote" rows="20" style="width: 100%; font-size: 18px;">
              </textarea>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import {appointmentService} from "@/_services/appointments.service";
import router from "@/router";
import {formatDate, formatDateTime} from "@/_helpers";

export default {
  methods: {
    formatDateTime(date) {
      return formatDateTime(date)
    },
    formatPrefDate(date) {
      return formatDate(date)
    },
    getAppointment() {
      if (this.noteChanged) {
        if (!confirm("Poznámka byla upravena a nebyla uložena. Opravdu ji chcete zahodit?")) {
          return
        }
      }
      this.noteSaved = false
      this.noteChanged = false
      this.loading = true
      appointmentService.getAppointmentDetail(this.id).then(appointment => {
            this.appointment = appointment
            this.loading = false
          }
      )
    },
    saveNote() {
      appointmentService.setAppointmentOperatorNote(this.id, this.appointment.operatorNote).then(() => {
        this.noteChanged = false
        this.noteSaved = true
      })
    },
    operatorNoteChanged() {
      this.noteSaved = false
      this.noteChanged = true
    },
    process() {
      appointmentService.setAppointmentStatus(this.id, "IN_PROGRESS").then(() =>
          this.getAppointment()
      ).catch(error => {
        alert("Není možné zprocesovat registraci: " + error)
      })
    },
    returnAppointment() {
      if (!this.noteChanged)
      appointmentService.setAppointmentStatus(this.id, "NEW").then(() =>
          router.push("/dashboard/appointments-waiting")
      )
    },
    completed() {
      if (this.appointment.operatorNote != null && this.appointment.operatorNote !== "" && !this.noteChanged) {
        appointmentService.setAppointmentStatus(this.id, "COMPLETED").then(() =>
            router.push("/dashboard/appointments-archive")
        )
      } else {
        alert("Objednání musí mít vyplněnou poznámku (minimálně datum a čas objednání)")
      }
    },
    canceled() {
      if (confirm("Opravdu chete zrušit objednání? Nebudete se k ní už moci vrátit!")) {
        if (this.appointment.operatorNote != null && this.appointment.operatorNote !== "" && !this.noteChanged) {
          appointmentService.setAppointmentStatus(this.id, "CANCELED").then(() =>
              router.push("/dashboard/appointments-waiting")
          ).catch(error => alert(error))
        } else {
          alert("Nelze zrušit objednání bez komentáře.")
        }
      }
    },
    restoreAppointment() {
      if(confirm("Opravdu chcete obnovit objednání? Stane se z ní nové a celý proces bude nutné absolvovat znovu!")) {
        appointmentService.setAppointmentStatus(this.id, "NEW").then(() =>
            //router.push("/dashboard/appointments-waiting")
            this.getAppointment()
        )
      }
    }
  },
  data() {
    return {
      id: '',
      loading: false,
      timer: '',
      appointment: null,
      noteChanged: false,
      noteSaved: false,
    }
  },
  created() {
    this.id = this.$route.params.id
    this.getAppointment()
  }
}
</script>
