<template>
  <div>
    <Breadcrumbs title="Sledování novinek"/>
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-12" style="text-align: right;padding-bottom: 20px">
          <button class="btn btn-primary" v-on:click="getSubscriptions">Obnovit data</button>
        </div>

        <!-- New subscriptions -->
        <div class="col-md-12 col-lg-12">
          <div class="card">
            <div class="card-header">
              <h5>Aktivní odběr novinek</h5>
              <span v-if="!loading">Celkem {{subscriptions.length}}</span>
            </div>

            <div class="card-body">
              <div class="text-center loading" v-if="loading">
                Nahrávám ...
              </div>

              <b-row v-if="!loading">
                <b-col xl="6">
                  <b-input-group class="datatable-btn">
                    <b-form-input v-model="filter" placeholder="Vyhledat"></b-form-input>
                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''">Vyčistit</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>

                <b-col xl="6">
                  <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <div class="table-responsive datatable-vue" v-if="!loading">
                <b-table
                    show-empty
                    stacked="md"
                    :items="subscriptions"
                    :fields="tableFields"
                    :filter="filter"
                    :current-page="currentPage"
                    :per-page="perPage"
                    @filtered="onFiltered"
                >
                  <template v-slot:cell(action)="{ item }">
                    <span><b-btn @click="detail(item)">Detail</b-btn></span>
                  </template>
                  <template v-slot:cell(createdAt)="{ item }">
                    <span>{{ formatDate(item.createdAt) }}</span>
                  </template>
                </b-table>
              </div>

              <b-col md="6" class="my-1 p-0" v-if="!loading">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    class="my-0"
                ></b-pagination>
              </b-col>
            </div>
          </div>
        </div>
        <!-- END New subscriptions -->

      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import {subscriptionService} from "@/_services/subscriptions.service";
import router from "@/router";
import {formatDateTime} from "@/_helpers";

export default {
  methods: {
    formatDate(date) {
      return formatDateTime(date)
    },
    getSubscriptions() {
      this.loading = true
      subscriptionService.getActiveSubscriptions().then(subscriptions => {
            this.subscriptions = subscriptions.data
            this.loading = false
          }
      )
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  },
  data() {
    return {
      loading: false,
      subscriptions: [],
      filter: null,

      tableFields: [
        { key: 'email', label: 'E-mail', sortable: true },
        { key: 'createdAt', label: 'Čas nastavení sledování', sortable: true },
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 200,
      pageOptions: [10, 50, 200, 1000],
    }
  },
  created() {
    this.getSubscriptions()
  },
  beforeUnmount() {
    this.cancelAutoUpdate();
  }
}
</script>
