<template xmlns="http://www.w3.org/1999/html">
  <div>
    <Breadcrumbs title="Profil"/>

    <!-- Change password modal -->
    <b-modal id="change-password-modal" size="sm" title="Změnit heslo" ok-title="Změnit" cancel-title="Zrušit"
             class="theme-modal"
             @show="resetModal"
             @hidden="resetModal"
             @ok="changePasswordOk"
    >
      <form class="theme-form" ref="changePasswordForm" id="changePasswordForm" @submit.stop.prevent="changePassword">
        <div class="form-group">
          <label for="username">Současné heslo:</label>
          <input
              type="password"
              v-model="oldPassword"
              name="firstName"
              class="form-control"
              required
              :class="{ 'is-invalid': changePasswordFormSubmitted && !oldPassword }"
          />
        </div>
        <div class="form-group">
          <label for="username">Nové heslo:</label>
          <input
              type="password"
              v-model="newPassword"
              name="firstName"
              minlength="8"
              class="form-control"
              required
              :class="{ 'is-invalid': changePasswordFormSubmitted && !newPassword }"
          />
        </div>
        <div class="form-group">
          <label for="username">Nové heslo pro kontrolu:</label>
          <input
              type="password"
              v-model="newPasswordCheck"
              name="firstName"
              minlength="8"
              class="form-control"
              required
              :class="{ 'is-invalid': changePasswordFormSubmitted && !newPasswordCheck }"
          />
        </div>
      </form>
    </b-modal>

    <div class="container-fluid">
      <div class="row">
        <!-- Profil -->
        <div class="col-12">
          <div class="card" v-if="!loading">
            <div class="card-header">
              <div class="row">
                <div class="col-6">
                  <h5>{{ profile.firstName }} {{ profile.lastName }}</h5>
                </div>
                <div class="col-6 text-right">
                  <b-button v-b-modal.change-password-modal variant="warning">Změnit heslo</b-button>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <table class="table">
                    <tr>
                      <th>Uživatelské jméno:</th>
                      <td>{{ profile.username }}</td>
                    </tr>
                    <tr>
                      <th>E-mail:</th>
                      <td>{{ profile.email }}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import {userService} from "@/_services/user.service";
import {formatDateTime} from "@/_helpers";

export default {
  data() {
    return {
      id: null,
      profile: null,
      loading: true,

      oldPassword: null,
      newPassword: null,
      newPasswordCheck: null,
      changePasswordFormSubmitted: false,
    }
  },
  methods: {
    format(date) {
      return formatDateTime(date)
    },
    resetModal() {
      this.oldPassword = null
      this.newPassword = null
      this.newPasswordCheck = null
      this.changePasswordFormSubmitted = false
    },
    changePasswordOk(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.changePassword()
    },
    changePassword() {
      this.changePasswordFormSubmitted = false
      if (this.newPassword != null && this.newPassword === this.newPasswordCheck) {
        userService.changeProfilePassword(this.oldPassword, this.newPassword).then(result => {
          this.$nextTick(() => {
            alert("Heslo bylo změněno")
            this.$bvModal.hide('change-password-modal')
          })
        }).catch(error => {
              if (error.message === "Passwords dont match") {
                alert("Nepodařilo se změnit heslo: Špatné původní heslo")
              } else {
                alert("Nepodařilo se změnit heslo: " + error.message)
              }
            }
        )
      } else {
        alert("Neplatné heslo nebo se hesla neshodují")
      }
    },
    getProfile() {
      userService.getProfile().then(profile => {
        this.profile = profile
        this.loading = false
      })
    },
  },
  created() {
    this.id = this.$route.params.id
    this.getProfile()
  }
}
</script>
