<template>
  <div class="header-wrapper row m-0">
    <div class="header-logo-wrapper">
      <div class="logo-wrapper">
        <router-link to="/">
          <img class="img-fluid" src="../assets/images/logo/logo_small.png" alt />
        </router-link>
      </div>
      <div class="toggle-sidebar" @click="toggle_sidebar">
        <feather
          class="status_toggle middle sidebar-toggle"
          type="sliders"
          id="sidebar-toggle"
        ></feather>
      </div>
    </div>
    <div class="left-header col horizontal-wrapper pl-0">
<!--      <ul class="horizontal-menu">-->
<!--        <li class="mega-menu outside">-->
<!--          <a-->
<!--            class="nav-link"-->
<!--            href="javascript:void(0);"-->
<!--          >-->
<!--            <feather type="layers"></feather><span>Bonus Ui</span>-->
<!--          </a>-->
<!--        </li>-->
<!--        <li class="level-menu outside">-->
<!--          <a class="nav-link" href="#">-->
<!--            <feather type="inbox"></feather>-->
<!--            <span>Level Menu</span></a-->
<!--          >-->
<!--        </li>-->
<!--      </ul>-->
    </div>
    <div class="nav-right col-8 pull-right right-header p-0">
      <ul class="nav-menus">
<!--        <li>-->
<!--          <span class="header-search"-->
<!--            ><feather type="search" @click="search_open()"></feather-->
<!--          ></span>-->
<!--        </li>-->
<!--        <li class="onhover-dropdown">-->
<!--          <div class="notification-box">-->
<!--            <feather type="bell"></feather-->
<!--            ><span class="badge badge-pill badge-secondary">4 </span>-->
<!--          </div>-->
<!--          <ul class="notification-dropdown onhover-show-div">-->
<!--            <li>-->
<!--              <feather type="bell"></feather>-->
<!--              <h6 class="f-18 mb-0">Notitications</h6>-->
<!--            </li>-->
<!--            <li>-->
<!--              <p>-->
<!--                <i class="fa fa-circle-o mr-3 font-primary"> </i>Delivery-->
<!--                processing <span class="pull-right">10 min.</span>-->
<!--              </p>-->
<!--            </li>-->
<!--            <li>-->
<!--              <p>-->
<!--                <i class="fa fa-circle-o mr-3 font-success"></i>Order-->
<!--                Complete<span class="pull-right">1 hr</span>-->
<!--              </p>-->
<!--            </li>-->
<!--            <li>-->
<!--              <p>-->
<!--                <i class="fa fa-circle-o mr-3 font-info"></i>Tickets-->
<!--                Generated<span class="pull-right">3 hr</span>-->
<!--              </p>-->
<!--            </li>-->
<!--            <li>-->
<!--              <p>-->
<!--                <i class="fa fa-circle-o mr-3 font-danger"></i>Delivery-->
<!--                Complete<span class="pull-right">6 hr</span>-->
<!--              </p>-->
<!--            </li>-->
<!--            <li>-->
<!--              <a class="btn btn-primary" href="#">Check all notification</a>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </li>-->
<!--        <li class="onhover-dropdown">-->
<!--          <div class="notification-box"><feather type="star"></feather></div>-->
<!--          <Bookmark />-->
<!--        </li>-->
        <li>
          <div class="mode">
            <i class="fa fa-moon-o" v-show="mixLayout == 'light-only'" @click="customizeMixLayout('dark-only')"></i>
            <i class="fa fa-lightbulb-o" v-show="mixLayout == 'dark-only'" @click="customizeMixLayout('light-only')"></i>
          </div>
        </li>
<!--        <li class="cart-nav onhover-dropdown">-->
<!--          <div class="cart-box">-->
<!--            <feather type="shopping-cart"></feather>-->
<!--            <span class="badge badge-pill badge-primary">2</span>-->
<!--          </div>-->
<!--          <ul class="cart-dropdown onhover-show-div">-->
<!--            <li>-->
<!--              <h6 class="mb-0 f-20">Shoping Bag</h6>-->
<!--              <feather type="shopping-cart"></feather>-->
<!--            </li>-->
<!--            <li class="mt-0">-->
<!--              <div class="media">-->
<!--                <img-->
<!--                  class="img-fluid rounded-circle mr-3 img-60"-->
<!--                  src="../assets/images/ecommerce/01.jpg"-->
<!--                  alt=""-->
<!--                />-->
<!--                <div class="media-body">-->
<!--                  <span>V-Neck Shawl Collar Woman's Solid T-Shirt</span>-->
<!--                  <p>Yellow(#fcb102)</p>-->
<!--                  <div class="qty-box">-->
<!--                    <div class="input-group">-->
<!--                      <span class="input-group-prepend">-->
<!--                        <button-->
<!--                          class="btn quantity-left-minus"-->
<!--                          type="button"-->
<!--                          data-type="minus"-->
<!--                          data-field=""-->
<!--                        >-->
<!--                          <feather type="minus"></feather>-->
<!--                        </button>-->
<!--                      </span>-->
<!--                      <input-->
<!--                        class="form-control input-number"-->
<!--                        type="text"-->
<!--                        name="quantity"-->
<!--                        value="1"-->
<!--                      /><span class="input-group-prepend">-->
<!--                        <button-->
<!--                          class="btn quantity-right-plus"-->
<!--                          type="button"-->
<!--                          data-type="plus"-->
<!--                          data-field=""-->
<!--                        >-->
<!--                          <feather type="plus"></feather></button-->
<!--                      ></span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <h6 class="text-right text-muted">$299.00</h6>-->
<!--                </div>-->
<!--                <div class="close-circle">-->
<!--                  <a href="#"><feather type="x"></feather></a>-->
<!--                </div>-->
<!--              </div>-->
<!--            </li>-->
<!--            <li class="mt-0">-->
<!--              <div class="media">-->
<!--                <img-->
<!--                  class="img-fluid rounded-circle mr-3 img-60"-->
<!--                  src="../assets/images/ecommerce/03.jpg"-->
<!--                  alt=""-->
<!--                />-->
<!--                <div class="media-body">-->
<!--                  <span>V-Neck Shawl Collar Woman's Solid T-Shirt</span>-->
<!--                  <p>Yellow(#fcb102)</p>-->
<!--                  <div class="qty-box">-->
<!--                    <div class="input-group">-->
<!--                      <span class="input-group-prepend">-->
<!--                        <button-->
<!--                          class="btn quantity-left-minus"-->
<!--                          type="button"-->
<!--                          data-type="minus"-->
<!--                          data-field=""-->
<!--                        >-->
<!--                          <feather type="minus"></feather></button-->
<!--                      ></span>-->
<!--                      <input-->
<!--                        class="form-control input-number"-->
<!--                        type="text"-->
<!--                        name="quantity"-->
<!--                        value="1"-->
<!--                      /><span class="input-group-prepend">-->
<!--                        <button-->
<!--                          class="btn quantity-right-plus"-->
<!--                          type="button"-->
<!--                          data-type="plus"-->
<!--                          data-field=""-->
<!--                        >-->
<!--                          <feather type="plus"></feather></button-->
<!--                      ></span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <h6 class="text-right text-muted">$299.00</h6>-->
<!--                </div>-->
<!--                <div class="close-circle">-->
<!--                  <a href="#"><feather type="x"></feather></a>-->
<!--                </div>-->
<!--              </div>-->
<!--            </li>-->
<!--            <li>-->
<!--              <div class="total">-->
<!--                <h6 class="mb-2 mt-0 text-muted">-->
<!--                  Order Total : <span class="f-right f-20">$598.00</span>-->
<!--                </h6>-->
<!--              </div>-->
<!--            </li>-->
<!--            <li>-->
<!--              <router-link-->
<!--                class="btn btn-block btn-primary view-cart"-->
<!--                :to="'/ecommerce/cart'"-->
<!--              >-->
<!--                Go to shoping bag-->
<!--              </router-link>-->
<!--              <router-link-->
<!--                :to="'/ecommerce/checkout'"-->
<!--                class="btn btn-block btn-secondary view-cart"-->
<!--              >-->
<!--                Checkout-->
<!--              </router-link>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </li>-->
<!--        <li class="onhover-dropdown">-->
<!--          <feather type="message-square"></feather>-->
<!--          <ul class="chat-dropdown onhover-show-div">-->
<!--            <li>-->
<!--              <feather type="message-square"></feather>-->
<!--              <h6 class="f-18 mb-0">Message Box</h6>-->
<!--            </li>-->
<!--            <li>-->
<!--              <div class="media">-->
<!--                <img-->
<!--                  class="img-fluid rounded-circle mr-3"-->
<!--                  src="../assets/images/user/1.jpg"-->
<!--                  alt=""-->
<!--                />-->
<!--                <div class="status-circle online"></div>-->
<!--                <div class="media-body">-->
<!--                  <span>Erica Hughes</span>-->
<!--                  <p>Lorem Ipsum is simply dummy...</p>-->
<!--                </div>-->
<!--                <p class="f-12 font-success">58 mins ago</p>-->
<!--              </div>-->
<!--            </li>-->
<!--            <li>-->
<!--              <div class="media">-->
<!--                <img-->
<!--                  class="img-fluid rounded-circle mr-3"-->
<!--                  src="../assets/images/user/2.jpg"-->
<!--                  alt=""-->
<!--                />-->
<!--                <div class="status-circle online"></div>-->
<!--                <div class="media-body">-->
<!--                  <span>Kori Thomas</span>-->
<!--                  <p>Lorem Ipsum is simply dummy...</p>-->
<!--                </div>-->
<!--                <p class="f-12 font-success">1 hr ago</p>-->
<!--              </div>-->
<!--            </li>-->
<!--            <li>-->
<!--              <div class="media">-->
<!--                <img-->
<!--                  class="img-fluid rounded-circle mr-3"-->
<!--                  src="../assets/images/user/4.jpg"-->
<!--                  alt=""-->
<!--                />-->
<!--                <div class="status-circle offline"></div>-->
<!--                <div class="media-body">-->
<!--                  <span>Ain Chavez</span>-->
<!--                  <p>Lorem Ipsum is simply dummy...</p>-->
<!--                </div>-->
<!--                <p class="f-12 font-danger">32 mins ago</p>-->
<!--              </div>-->
<!--            </li>-->
<!--            <li class="text-center">-->
<!--              <a class="btn btn-primary" href="#">View All </a>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </li>-->
        <li class="maximize">
          <a
            class="text-dark"
            href="javascript:void(0)"
            @click="toggle_fullscreen()"
          >
            <feather type="maximize"></feather
          ></a>
        </li>
        <li class="profile-nav onhover-dropdown p-0 mr-0">
          <div class="media profile-media">
<!--            <img-->
<!--              class="b-r-10"-->
<!--              src="../assets/images/user/user.png"-->
<!--              alt=""-->
<!--            />-->
            <div class="media-body">
              <span>{{ userName }}</span>
              <p class="mb-0 font-roboto">
                {{ userRole }} <i class="middle fa fa-angle-down"></i>
              </p>
            </div>
          </div>
          <ul class="profile-dropdown onhover-show-div">
            <li>
              <router-link to="/dashboard/profile"><feather type="user"></feather><span>Účet </span></router-link>
            </li>
            <li>
              <a v-on:click="logout"><feather type="log-in"></feather><span>Odhlásit</span></a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <script class="result-template" type="text/x-handlebars-template">
      <div class="ProfileCard u-cf">
        <div class="ProfileCard-avatar">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-airplay m-0"
          >
            <path
              d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1"
            ></path>
            <polygon points="12 15 17 21 7 21 12 15"></polygon>
          </svg>
        </div>
        <div class="ProfileCard-details">
          <div class="ProfileCard-realName">
            name
          </div>
        </div>
      </div>
    </script>
    <script class="empty-template" type="text/x-handlebars-template">
      <div class="EmptyMessage">
        Your search turned up 0 results. This most likely means the backend is down, yikes!
      </div>
    </script>
  </div>
</template>
<script>
import router from "@/router";

const body = document.getElementsByTagName("body")[0];
import { mapState } from "vuex";
import Bookmark from "./bookmark";
import { userService } from "../_services"

export default {
  name: "Search",
  data() {
    return {
      terms: "",
      searchOpen: false,
      searchResult: false,
      searchResultEmpty: false,
      close_sidebar_var: false,
      clicked: false,
      mobile_toggle: false,
      mobile_search: false,
      openbonusUI: false,
      openLevelmenu: false,
      openlanguage: false,
      mobile_accordian: false,
      mixLayout: "light-only",
      userName: '-',
      userRole: '-'
    };
  },
  components: {
    Bookmark,
  },
  mounted() {
    if (localStorage.user) {
      const parsedUser = JSON.parse(localStorage.user)
      this.userName = parsedUser.firstName + ' ' + parsedUser.lastName
      this.userRole = parsedUser.role
    }
  },
  computed: {
    ...mapState({
      menuItems: (state) => state.menu.searchData,
      megamenuItems: (state) => state.menu.megamenu,
    }),
  },
  methods: {
    logout() {
      userService.logout()
      router.push("auth/login")
    },
    toggle_sidebar() {
      this.$store.dispatch("menu/opensidebar");
    },
    setNavActive(item) {
      this.$store.dispatch("menu/setBonusNavActive", item);
    },
    openlangpicker() {
      this.openlanguage = !this.openlanguage;
    },
    openlevelmenu() {
      this.openLevelmenu = !this.openLevelmenu;
    },
    openmegamenu() {
      this.openbonusUI = !this.openbonusUI;
    },
    closeBonusUI() {
      this.openbonusUI = false;
    },
    search_open() {
      this.searchOpen = true;
    },
    search_close() {
      this.searchOpen = false;
    },
    searchterm: function () {
      this.$store.dispatch("menu/searchTerm", this.terms);
    },
    changeLocale(locale) {
      this.setLang(locale);
    },
    mobileaccordian() {
      this.mobile_accordian = !this.mobile_accordian;
    },
    addFix() {
      body.classList.add("offcanvas");
      this.searchResult = true;
    },
    removeFix() {
      body.classList.remove("offcanvas");
      this.searchResult = false;
      this.terms = "";
    },
    toggle_fullscreen() {
      if (
        (document.fullScreenElement && document.fullScreenElement !== null) ||
        (!document.mozFullScreen && !document.webkitIsFullScreen)
      ) {
        if (document.documentElement.requestFullScreen) {
          document.documentElement.requestFullScreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullScreen) {
          document.documentElement.webkitRequestFullScreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    customizeMixLayout(val) {
      this.mixLayout = val;
      this.$store.dispatch("layout/setLayout", val);
    },
  },
  watch: {
    "$i18n.locale"(to, from) {
      if (from !== to) {
        this.$router.go(this.$route.path);
      }
    },
    menuItems: function () {
      this.terms ? this.addFix() : this.removeFix();
      if (!this.menuItems.length) this.searchResultEmpty = true;
      else this.searchResultEmpty = false;
    },
  },
};
</script>
