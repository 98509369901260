<template xmlns="http://www.w3.org/1999/html" xmlns="http://www.w3.org/1999/html">
  <div>
    <Breadcrumbs title="Detail zaměstnance"/>

    <!-- Edit employee modal -->
    <b-modal v-if="!loading" id="edit-employee-modal" size="lg" title="Upravit zaměstnance" ok-title="Upravit"
             cancel-title="Zrušit" class="theme-modal"
             ok-variant="warning"
             @show="resetModal"
             @hidden="resetModal"
             @ok="editEmployeeOk"
    >
      <form class="theme-form" ref="accountForm" id="accountForm" @submit.stop.prevent="editEmployee">
        <div class="row">
          <div class="col-6 col-sm-12">
            <div class="form-group">
              <label for="username">Pobočka</label>
              <select
                  type="text"
                  v-model="account.branch"
                  name="branch"
                  class="form-control"
                  required
                  :class="{ 'is-invalid': editFormSubmitted && !account.branch }"
              >
                <option value="BARRANDOV">Barrandov</option>
                <option value="GLOBAL">Globální</option>
              </select>
            </div>
          </div>

          <div class="col-6">
            <div class="form-group">
              <label for="username">Rodné číslo:</label>
              <input
                  type="text"
                  v-model="account.birthNumber"
                  name="birthNumber"
                  class="form-control"
              />
            </div>
          </div>

          <div class="col-6">
            <div class="form-group">
              <label for="username">Telefon:</label>
              <input
                  type="text"
                  v-model="account.phoneNumber"
                  name="phoneNumber"
                  class="form-control"
              />
            </div>
          </div>

          <div class="col-6">
            <div class="form-group">
              <label for="username">Zaměstnán od:</label>
              <input
                  type="date"
                  v-model="account.employeeFrom"
                  name="employeeFrom"
                  class="form-control"
              />
            </div>
          </div>

          <div class="col-6">
            <div class="form-group">
              <label for="username">Zaměstnán do:</label>
              <input
                  type="date"
                  v-model="account.employeeTo"
                  name="employeeTo"
                  class="form-control"
              />
            </div>
          </div>

          <div class="col-6">
            <div class="form-group">
              <label for="username">BOZP do:</label>
              <input
                  type="date"
                  v-model="account.bozpTo"
                  name="bozpTo"
                  class="form-control"
              />
            </div>
          </div>

          <div class="col-6">
            <div class="form-group">
              <label for="username">Hrubá mzda:</label>
              <input
                  type="number"
                  v-model="account.mainSalary"
                  name="mainSalary"
                  class="form-control"
              />
            </div>
          </div>

          <div class="col-6">
            <div class="form-group">
              <label for="username">Na IČO:</label>
              <input
                  type="number"
                  v-model="account.secondarySalary"
                  name="secondarySalary"
                  class="form-control"
              />
            </div>
          </div>

          <div class="col-6">
            <div class="form-group">
              <label for="username">Pracovních hodin:</label>
              <input
                  type="number"
                  v-model="account.workingHours"
                  name="workingHours"
                  class="form-control"
              />
            </div>
          </div>

        </div>


        <div class="form-group">
          <label for="username">Poznámka:</label>
          <textarea
              v-model="account.note"
              name="note"
              class="form-control"
          />
        </div>
      </form>
    </b-modal>
    <!-- account employee modal END -->

    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <!-- Accounts -->
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-6">
                  <h5><span v-if="!loading">{{ account.firstName }} {{ account.lastName }}</span></h5>
                </div>
                <div class="col-6 text-right">
                  <b-button v-b-modal.edit-employee-modal variant="warning">Upravit</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12">

          <div class="card-body" v-if="loading">
            <div class="text-center loading">
              Nahrávám ...
            </div>
          </div>

          <div class="row" v-if="!loading">
            <div class="col-lg-4 col-sm-6">
              <div class="card">
                <div class="card-body">
                  <h6>Osobní údaje</h6>
                  <table class="table">
                    <tr>
                      <th>Jméno</th>
                      <td>{{ account.firstName }} {{ account.lastName }}</td>
                    </tr>
                    <tr>
                      <th>Uživatelské jméno</th>
                      <td>{{ account.username }}</td>
                    </tr>
                    <tr>
                      <th>Rodné číslo</th>
                      <td>{{ account.birthNumber }}</td>
                    </tr>
                    <tr>
                      <th>Telefon</th>
                      <td>{{ account.phoneNumber }}</td>
                    </tr>
                    <tr>
                      <th>E-mail</th>
                      <td>{{ account.email }}</td>
                    </tr>
                    <tr>
                      <th>Role</th>
                      <td>{{ account.role }}</td>
                    </tr>
                    <tr>
                      <th>Zaměstnán od</th>
                      <td>{{ account.employeeFrom }}</td>
                    </tr>
                    <tr>
                      <th>Zaměstnán do</th>
                      <td v-if="account.employeeTo">{{ account.employeeTo }}</td>
                      <td v-if="!account.employeeTo">-</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-sm-6">
              <div class="card">
                <div class="card-body">
                  <h6>Mzdové údaje</h6>
                  <table class="table" v-if="!loading">
                    <tr>
                      <th>Hrubá mzda</th>
                      <td v-if="account.mainSalary">{{ account.mainSalary.toLocaleString() }} Kč</td>
                      <td v-if="!account.mainSalary">-</td>
                    </tr>
                    <tr>
                      <th>Na IČO</th>
                      <td v-if="account.secondarySalary">{{ account.secondarySalary.toLocaleString() }} Kč</td>
                      <td v-if="!account.secondarySalary">-</td>
                    </tr>
                    <tr>
                      <th>Pracovních hodin</th>
                      <td v-if="account.workingHours">{{ account.workingHours }}h</td>
                      <td v-if="!account.workingHours">-</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-sm-6">
              <div class="card">
                <div class="card-body">
                  <h6>Ostatní údaje</h6>
                  <table class="table" v-if="!loading">
                    <tr>
                      <th>BOZP do</th>
                      <td v-if="account.bozpTo">
                        <span v-if="isBeforeNow(account.bozpTo)" class="date-warning">{{ formatDate(account.bozpTo) }}</span>
                        <span v-if="!isBeforeNow(account.bozpTo)">{{ formatDate(account.bozpTo) }}</span>
                      </td>
                      <td v-if="!account.bozpTo">-</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>

          </div>

          <div class="row" v-if="!loading">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <h6>Poznámka</h6>

                  <p v-if="account.note">{{ account.note }}</p>
                  <p v-if="!account.note">-</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <!-- Container-fluid Ends-->
</template>

<script>
import {userService} from "@/_services/user.service";
import {formatDate, formatDateTime, isBeforeNow} from "@/_helpers";

export default {
  data() {
    return {
      id: null,
      account: null,
      loading: true,
      editFormSubmitted: false
    }
  },
  methods: {
    formatDate,
    isBeforeNow,
    checkFormValidity() {
      return this.$refs.accountForm.checkValidity()
    },
    format(date) {
      return formatDateTime(date)
    },
    resetModal() {
      this.editFormSubmitted = false
      this.changePasswordFormSubmitted = false
      this.getAccount()
    },
    editEmployeeOk(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.editEmployee()
    },
    editEmployee() {
      this.editFormSubmitted = true
      if (!this.checkFormValidity()) {
        return
      }

      const request = {
        firstName: this.account.firstName,
        lastName: this.account.lastName,
        username: this.account.username,
        email: this.account.email,
        role: this.account.role,
        employeeFrom: this.account.employeeFrom,
        employeeTo: this.account.employeeTo,
        bozpTo: this.account.bozpTo,
        mainSalary: this.account.mainSalary,
        secondarySalary: this.account.secondarySalary,
        workingHours: this.account.workingHours,
        branch: this.account.branch,
        birthNumber: this.account.birthNumber,
        phoneNumber: this.account.phoneNumber,
        note: this.account.note,
      }
      userService.updateAccount(this.account.id, request).then(result => {
        this.getAccount()
        this.$nextTick(() => {
          this.$bvModal.hide('edit-employee-modal')
        })
      }).catch(error =>
          alert("Nepodařilo se upravit zaměstnance: " + error)
      )

    },
    getAccount() {
      userService.getAccount(this.id).then(account => {
        this.account = account
        this.loading = false
      })
    },
  },
  created() {
    this.id = this.$route.params.id
    this.getAccount()
  }
}
</script>
