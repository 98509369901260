<template>
  <div>
    <Breadcrumbs title="Registrace"/>
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-12" style="text-align: right;padding-bottom: 20px">
          <button class="btn btn-primary" v-on:click="getRegistrations">Obnovit data</button>
        </div>

        <!-- New registrations -->
        <div class="col-md-12 col-lg-12">
          <div class="card">
            <div class="card-header">
              <h5>Objednané registrace</h5>
            </div>

            <div class="card-body">
              <div class="text-center loading" v-if="loading">
                Nahrávám ...
              </div>

              <b-row v-if="!loading">
                <b-col xl="6">
                  <b-input-group class="datatable-btn">
                    <b-form-input v-model="filter" placeholder="Vyhledat"></b-form-input>
                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''">Vyčistit</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>

                <b-col xl="6">
                  <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <div class="table-responsive datatable-vue">
                <b-table
                    show-empty
                    stacked="md"
                    :items="registrations"
                    :fields="tableFields"
                    :filter="filter"
                    :current-page="currentPage"
                    :per-page="perPage"
                    @filtered="onFiltered"
                >
                  <template v-slot:cell(action)="{ item }">
                    <span><b-btn @click="register(item)">Registrovat</b-btn></span>
                  </template>
                  <template v-slot:cell(createdAt)="{ item }">
                    <span>{{ formatDate(item.createdAt) }}</span>
                  </template>
                </b-table>
              </div>

              <b-col md="6" class="my-1 p-0">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    class="my-0"
                ></b-pagination>
              </b-col>
            </div>
          </div>
        </div>
        <!-- END New registrations -->

      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import {registrationService} from "@/_services/registrations.service";
import router from "@/router";
import {formatDateTime} from "@/_helpers";

export default {
  methods: {
    formatDate(date) {
      return formatDateTime(date)
    },
    getRegistrations() {
      this.loading = true
      this.registrationsNew = []
      this.registrationsInProgress = []
      registrationService.getRegistrations("appointed").then(registrations => {
            this.registrations = registrations.data
            this.totalRows = this.registrations.length
            this.loading = false
          }
      )
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
    continueRegistration(id) {
      router.push("/dashboard/registration-detail/" + id)
    },
    processRegistration(id) {
      registrationService.setRegistrationStatus(id, "IN_PROGRESS").then(() =>
          router.push("/dashboard/registration-detail/" + id)
      ).catch(error => {
        alert("Není možné zprocesovat registraci: " + error)
        this.getRegistrations()
      })
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    register(row) {
      router.push("/dashboard/registration-detail/" + row.id)
    }
  },
  data() {
    return {
      loading: false,
      timer: '',
      registrations: [],
      filter: null,

      tableFields: [
        { key: 'firstName', label: 'Jméno', sortable: true },
        { key: 'lastName', label: 'Příjmení', sortable: true },
        { key: 'createdAt', label: 'Čas registrace', sortable: true },
        { key: 'branch', label: 'Pobočka', sortable: true },
        { key: 'action', label: 'Akce', sortable: false },
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      pageOptions: [10, 20, 50, 100],
    }
  },
  created() {
    this.getRegistrations()
    this.timer = setInterval(this.getRegistrations, 300000);
  },
  beforeUnmount() {
    this.cancelAutoUpdate();
  }
}
</script>
