import {userService} from '../../_services';
import router from '../../router/index';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
    ? {status: {loggedIn: true}, user, error: null}
    : {status: {}, user: null, error: null};

export const authentication = {
  namespaced: true,
  state: initialState,
  getters: {},
  actions: {
    login({dispatch, commit}, {username, password}) {
      commit('loginRequest', {username});
      userService.login(username, password)
      .then(
          user => {
            commit('loginSuccess', user);
            router.push('/');
          },
          error => {
            commit('loginFailure', error);
            //dispatch('alert/error', error, {root: true});
          }
      )
    },
    logout({commit}) {
      userService.logout();
      commit('logout');
    }
  },
  mutations: {
    loginRequest(state, user) {
      state.status = {loggingIn: true};
      state.error = null
      state.user = user;
    },
    loginSuccess(state, user) {
      state.status = {loggedIn: true};
      state.error = null
      state.user = user;
    },
    loginFailure(state, error) {
      state.status = {};
      state.error = error
      state.user = null;
    },
    logout(state) {
      state.status = {};
      state.error = null
      state.user = null;
    }
  }
};
