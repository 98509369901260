<template xmlns="http://www.w3.org/1999/html">
  <div>
    <Breadcrumbs title="Nastavení webu"/>

    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <!-- Accounts -->
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-6">
                  <h5>Varování</h5>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="text-center loading" v-if="loading">
                Nahrávám ...
              </div>

              <form class="theme-form" ref="warningForm" id="warningForm" @submit.stop.prevent="editWarning">
              <table class="table" v-if="!loading">
                <tr>
                  <th>Aktivní:</th>
                  <td><input type="checkbox" v-model="warning.active" class="form-check-custom" /></td>
                </tr>
                <tr>
                  <th>Od:</th>
                  <td><input type="date" v-model="warning.from" /></td>
                </tr>
                <tr>
                  <th>Do:</th>
                  <td><input type="date" v-model="warning.to" /></td>
                </tr>
                <tr>
                  <th>Text CZ:</th>
                  <td><textarea class="form-control" v-model="warning.messageCz" /></td>
                </tr>
                <tr>
                  <th>Text EN:</th>
                  <td><textarea class="form-control" v-model="warning.messageEn" /></td>
                </tr>
                <tr>
                  <th>Text UA:</th>
                  <td><textarea class="form-control" v-model="warning.messageUa" /></td>
                </tr>
                <tr>
                  <td colspan="2">
                    <input type="submit" value="Uložit" class="btn btn-primary" />
                  </td>
                </tr>
              </table>
              </form>

            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import {warningService} from "@/_services/warning.service";
import {formatDateTime} from "@/_helpers";

export default {
  data() {
    return {
      warning: null,
      loading: true,
      editFormSubmitted: false,
    }
  },
  methods: {
    checkFormValidity() {
      return this.$refs.warningForm.checkValidity()
    },
    format(date) {
      return formatDateTime(date)
    },
    getDateOrNull(from) {
      if (from === "") return null
      return from;
    },
    editWarning() {
      this.editFormSubmitted = true
      if (!this.checkFormValidity()) {
        return
      }

      const request = {
        active: this.warning.active,
        from: this.getDateOrNull(this.warning.from),
        to: this.getDateOrNull(this.warning.to),
        messageCz: this.warning.messageCz,
        messageEn: this.warning.messageEn,
        messageUa: this.warning.messageUa,
      }
      warningService.updateWarning(request).then(result => {
        this.getWarning()
      }).catch(error =>
          alert("Nepodařilo se upravit warning: " + error)
      )
    },
    getWarning() {
      warningService.getWarning().then(warning => {
        this.warning = warning
        this.loading = false
      })
    },
  },
  created() {
    this.getWarning()
  }
}
</script>
