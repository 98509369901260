<template xmlns="http://www.w3.org/1999/html">
  <div>
    <Breadcrumbs title="Zaměstnanci"/>

    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <!-- Accounts -->
        <div class="col-12">

          <div class="row">
          <!-- Statistics -->
          <div class="col-12">
            <div class="row">
              <div class="col-lg-2 col-sm-6">
                <div class="card">
                  <div class="card-body" style="align-content: center; text-align: center">
                    <h5>Zaměstnanců</h5>
                    <span class="dashboard-value">{{ statistics.totalEmployees }}</span>
                  </div>
                </div>
              </div>

              <!-- total salary -->
              <div class="col-lg-2 col-sm-6">
                <div class="card">
                  <div class="card-body" style="align-content: center; text-align: center">
                    <h5>Celkem hrubá + pojistky</h5>
                    <span class="dashboard-value">{{ statistics.totalSalary.toLocaleString() }} Kč</span>
                  </div>
                </div>
              </div>

              <!-- total ico -->
              <div class="col-lg-2 col-sm-6">
                <div class="card">
                  <div class="card-body" style="align-content: center; text-align: center">
                    <h5>Celkem na IČO</h5>
                    <span class="dashboard-value">{{ statistics.totalIco.toLocaleString() }} Kč</span>
                  </div>
                </div>
              </div>

              <!-- Total -->
              <div class="col-lg-2 col-sm-6">
                <div class="card">
                  <div class="card-body" style="align-content: center; text-align: center">
                    <h5>Celkem náklady</h5>
                    <span class="dashboard-value">{{ statistics.total.toLocaleString() }} Kč</span>
                  </div>
                </div>
              </div>
            </div>

          </div>
          </div>

          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-6">
                  <h5>Zaměstnanci</h5>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="text-center loading" v-if="loading">
                Nahrávám ...
              </div>

              <b-row v-if="!loading">
                <b-col xl="6">
                  <b-input-group class="datatable-btn">
                    <b-form-input v-model="filter" placeholder="Vyhledat"></b-form-input>
                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''">Vyčistit</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>

                <b-col xl="6">
                  <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <div class="table-responsive datatable-vue">
                <b-table
                    show-empty
                    stacked="md"
                    :items="employees"
                    :fields="tableFields"
                    :filter="filter"
                    :current-page="currentPage"
                    :per-page="perPage"
                    @filtered="onFiltered"
                >
                  <template v-slot:cell(action)="{ item }">
                    <span><b-btn @click="detail(item.id)">Detail</b-btn></span>
                  </template>
                  <template v-slot:cell(mainSalary)="{ item }">
                    <span>{{ Math.max(item.mainSalary, 0).toLocaleString() }} Kč</span>
                  </template>
                  <template v-slot:cell(secondarySalary)="{ item }">
                    <span>{{ Math.max(item.secondarySalary, 0).toLocaleString() }} Kč</span>
                  </template>
                  <template v-slot:cell(totalCost)="{ item }">
                    <span>{{ Math.max(item.totalCost, 0).toLocaleString() }} Kč</span>
                  </template>
                  <template v-slot:cell(employeeFrom)="{ item }">
                    <span>{{ formatDate(item.employeeFrom) }}</span>
                  </template>
                  <template v-slot:cell(employeeTo)="{ item }">
                    <span>{{ formatDate(item.employeeTo) }}</span>
                  </template>
<!--                  <template v-slot:cell(bozpTo)="{ item }" class="date-warning">-->
                  <template v-slot:cell(bozpTo)="{ item }">
                    <slot>
                      <span v-if="isBeforeNow(item.bozpTo)" class="date-warning">{{ formatDate(item.bozpTo) }}</span>
                      <span v-if="!isBeforeNow(item.bozpTo)">{{ formatDate(item.bozpTo) }}</span>
                    </slot>

                  </template>
                </b-table>
              </div>

              <b-col md="6" class="my-1 p-0">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    class="my-0"
                ></b-pagination>
              </b-col>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import {userService} from "@/_services/user.service";
import {formatDate, formatDateTime, isBeforeNow} from "@/_helpers";
import router from "@/router";
import {formatNum} from "leaflet/src/core/Util";

export default {
  data() {
    return {
      newAccount: {},
      submitted: false,
      employees: [],
      loading: false,
      filter: null,
      statistics: {
        totalEmployees: "-",
        totalSalary: "-",
        totalIco: "-",
        total: "-"
      },
      tableFields: [
        { key: 'fullName', label: 'Jméno', sortable: true },
        { key: 'role', label: 'Role', sortable: true },
        { key: 'employeeFrom', label: 'Zaměstnán od', sortable: true },
        { key: 'employeeTo', label: 'Zaměstnán do', sortable: true },
        { key: 'bozpTo', label: 'BOZP do', sortable: true },
        { key: 'mainSalary', label: 'Hrubá mzda', sortable: true },
        { key: 'secondarySalary', label: 'Na IČO', sortable: true },
        { key: 'totalCost', label: 'Celkový náklad', sortable: true },
        { key: 'branch', label: 'Pobočka', sortable: true },
        { key: 'action', label: 'Akce', sortable: false },
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, 50],
    }
  },
  methods: {
    formatDate,
    formatNum,
    isBeforeNow,
    format(date) {
      return formatDateTime(date)
    },
    getEmployees() {
      userService.getAccounts().then(employees => {
        this.employees = employees.data.map(employee => {
          return {
            ...employee,
            fullName: `${employee.firstName} ${employee.lastName}`,
            totalCost: this.calculateTotalPayrollWithTax(employee.mainSalary) + employee.secondarySalary
          };
        })
        this.statistics = {
          totalEmployees: this.employees.length,
          totalSalary: this.employees.reduce((sum, employee) => sum + this.calculateTotalPayrollWithTax(employee.mainSalary), 0),
          totalIco: this.employees.reduce((sum, employee) => sum + employee.secondarySalary, 0),
        }
        this.statistics = {
          ...this.statistics,
          total: this.statistics.totalSalary + this.statistics.totalIco
        }
      })
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    detail(id) {
      router.push("/dashboard/employee/" + id)
    },
    calculateTotalPayrollWithTax(salary) {
      const soc = salary * 0.071
      const zdrav = salary * 0.045
      const incomeTax = Math.max(((salary*0.15)-2570), 0)
      const companyTax = salary * 0.338
      const insurance = (salary * 0.065) + (salary * 0.045)
      return salary + companyTax
    }
  },
  created() {
    this.getEmployees()
  },
}
</script>
