// import config from 'config';

import Env from '../data/env'

/* eslint-disable */
import {authHeader} from '../_helpers';

export const userService = {
  login,
  logout,
  getAccounts,
  addAccount,
  getAccount,
  updateAccount,
  getRoles,
  addRole,
  changePassword,
  changeProfilePassword,
  getProfile
};

function login(username, password) {
  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({
      subject: username,
      password: password,
      tokenType: "USER"
    })
  };

  return fetch(Env.authServerUrl + `/api/v1/login`, requestOptions)
  .then(handleLogin)
  .then(user => {
    if (user.token) {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('token', user.token);
      localStorage.setItem('tokenExpiration', user.expiration);
      localStorage.setItem('userRole', user.role);
    }
    return user;
  });

}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
  localStorage.removeItem('token');
  localStorage.removeItem('tokenExpiration');
}

function getAccounts() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(Env.authServerUrl + `/api/v1/accounts`, requestOptions).then(
      handleResponse);
}

function getAccount(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(Env.authServerUrl + `/api/v1/accounts/` + id,
      requestOptions).then(handleResponse);
}

function addAccount(account) {
  const requestOptions = {
    method: 'POST',
    headers: {...authHeader(), ...{'Content-Type': 'application/json'}},
    body: JSON.stringify(account)
  };

  return fetch(Env.authServerUrl + `/api/v1/accounts`, requestOptions).then(
      handleResponse);
}

function updateAccount(id, account) {
  const requestOptions = {
    method: 'PUT',
    headers: {...authHeader(), ...{'Content-Type': 'application/json'}},
    body: JSON.stringify(account)
  };

  return fetch(Env.authServerUrl + `/api/v1/accounts/` + id,
      requestOptions).then(handleResponse);
}

function changePassword(id, newPassword) {
  const requestOptions = {
    method: 'PUT',
    headers: {...authHeader(), ...{'Content-Type': 'application/json'}},
    body: JSON.stringify({
      newPassword
    })
  };

  return fetch(Env.authServerUrl + `/api/v1/accounts/` + id + `/password`,
      requestOptions).then(handleResponse);
}

function getRoles() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(Env.authServerUrl + `/api/v1/roles`, requestOptions).then(
      handleResponse);
}

function addRole(role) {
  const requestOptions = {
    method: 'POST',
    headers: {...authHeader(), ...{'Content-Type': 'application/json'}},
    body: JSON.stringify(role)
  };

  return fetch(Env.authServerUrl + `/api/v1/roles`, requestOptions).then(
      handleResponse);
}

function getProfile() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(Env.authServerUrl + `/api/v1/profile`, requestOptions).then(
      handleResponse);
}

function changeProfilePassword(oldPassword, newPassword) {
  const requestOptions = {
    method: 'PUT',
    headers: {...authHeader(), ...{'Content-Type': 'application/json', 'Accept': 'application/json'}},
    body: JSON.stringify({
      oldPassword,
      newPassword,
    })
  };

  return fetch(Env.authServerUrl + `/api/v1/profile/password`,
      requestOptions).then(handleResponse);
}

function handleLogin(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        return Promise.reject("WRONG_USER_OR_PASSWORD");
      }
      const error = (data && data.message) || response.statusText;
      console.error(error)
      return Promise.reject("Nepodařilo se kontaktovat server");
    }

    return data;
  });
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        location.reload(true);
      }
      //const error = (data && data.message) || response.statusText;
      return Promise.reject(data);
    }

    return data;
  });
}
