<template>
  <div>
    <Breadcrumbs title="Objednání prohlídky"/>
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-12" style="text-align: right;padding-bottom: 20px">
          <button class="btn btn-primary" v-on:click="getAppointments">Obnovit data</button>
        </div>

        <!-- New appointments -->
        <div class="col-md-12 col-lg-6">
          <div class="card">
            <div class="card-header">
              <h5>Čekající objednání</h5>
            </div>

            <div class="card-body">
              <div class="text-center loading" v-if="loading">
                Nahrávám ...
              </div>
              <table class="table" v-if="!loading">
                <tr>
                  <th>Jméno</th>
                  <th>Čas vytvoření</th>
                  <th>Komentář</th>
                  <th>Akce</th>
                </tr>
                <tr v-for="appointment in appointmentsNew">
                  <td>{{ appointment.firstName }} {{ appointment.lastName }}</td>
                  <td>{{ formatDate(appointment.createdAt) }}</td>
                  <td>
                    <feather type="message-circle" v-if="appointment.operatorNote != null" :id="'operator-note-popover-' + appointment.id" />
                    <b-popover :target="'operator-note-popover-' + appointment.id" placement="left" triggers="hover">
                      <p>{{ appointment.operatorNote }}</p>
                    </b-popover>
                  </td>
                  <td>
                    <button v-if="appointment.status === 'NEW'" v-on:click="processAppointment(appointment.id)" class="btn btn-primary btn-sm">Procesovat</button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <!-- END New appointments -->

        <!-- InProgress appointments -->
        <div class="col-md-12 col-lg-6">
          <div class="card">
            <div class="card-header">
              <h5>Objednání v procesu</h5>
            </div>

            <div class="card-body">
              <div class="text-center loading" v-if="loading">
                Nahrávám ...
              </div>
              <table class="table" v-if="!loading">
                <tr>
                  <th>Jméno</th>
                  <th>Čas objednání</th>
                  <th>Komentář</th>
                  <th>Akce</th>
                </tr>
                <tr v-for="appointment in appointmentsInProgress">
                  <td>{{ appointment.firstName }} {{ appointment.lastName }}</td>
                  <td>{{ formatDate(appointment.createdAt) }}</td>
                  <td>
                    <feather type="message-circle" v-if="appointment.operatorNote != null" :id="'operator-note-popover-process-' + appointment.id" />
                    <b-popover :target="'operator-note-popover-process-' + appointment.id" placement="left" triggers="hover">
                      <p>{{ appointment.operatorNote }}</p>
                    </b-popover>
                  </td>
                  <td>
                    <button v-on:click="continueAppointment(appointment.id)" class="btn btn-warning btn-sm">Pokračovat</button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <!-- END InProgress appointments -->

      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import {appointmentService} from "@/_services/appointments.service";
import router from "@/router";
import {formatDateTime} from "@/_helpers";

export default {
  methods: {
    formatDate(date) {
      return formatDateTime(date)
    },
    getAppointments() {
      this.loading = true
      this.appointmentsNew = []
      this.appointmentsInProgress = []
      appointmentService.getAppointments("new,in_progress").then(appointments => {
            appointments.data.forEach((appointment, index) => {
              if (appointment.status === "NEW") {
                this.appointmentsNew.push(appointment)
              }
              if (appointment.status === "IN_PROGRESS") {
                this.appointmentsInProgress.push(appointment)
              }
            })
            this.loading = false
          }
      )
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
    continueAppointment(id) {
      router.push("/dashboard/appointments-detail/" + id)
    },
    processAppointment(id) {
      appointmentService.setAppointmentStatus(id, "IN_PROGRESS").then(() =>
          router.push("/dashboard/appointments-detail/" + id)
      ).catch(error => {
        alert("Není možné zprocesovat objednávku: " + error)
        this.getAppointments()
      })
    }
  },
  data() {
    return {
      loading: false,
      timer: '',
      appointmentsNew: [],
      appointmentsInProgress: []
    }
  },
  created() {
    this.getAppointments()
    this.timer = setInterval(this.getAppointments, 300000);
  },
  beforeUnmount() {
    this.cancelAutoUpdate();
  }
}
</script>
