// import config from 'config';

import Env from '../data/env'

/* eslint-disable */
import {authHeader} from '../_helpers';

export const warningService = {
  getWarning,
  updateWarning,
};


function getWarning() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(Env.backendServerUrl + `/api/v1/web-config/warning`,
      requestOptions).then(handleResponse);
}

function updateWarning(warning) {
  const requestOptions = {
    method: 'PUT',
    headers: {...authHeader(), ...{'Content-Type': 'application/json'}},
    body: JSON.stringify(warning)
  };

  return fetch(Env.backendServerUrl + `/api/v1/web-config/warning`,
      requestOptions).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        location.reload(true);
      }
      //const error = (data && data.message) || response.statusText;
      return Promise.reject(data);
    }

    return data;
  });
}
