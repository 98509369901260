// import config from 'config';

/* eslint-disable */
import router from "@/router";
import Env from '../data/env'
import {userService} from "@/_services/user.service";

export const appointmentService = {
  getAppointments,
  getAppointmentDetail,
  setAppointmentStatus,
  setAppointmentOperatorNote
};

function getAppointments(statuses) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.token
    }
  };

  return fetch(Env.backendServerUrl + `/api/v1/appointments?status=` + statuses, requestOptions)
  .then(handleResponse)
  .then(appointments => {
    return appointments
  });
}

function getAppointmentDetail(id) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.token
    }
  };

  return fetch(Env.backendServerUrl + `/api/v1/appointments/` + id, requestOptions)
  .then(handleResponse)
  .then(appointment => {
    return appointment
  });
}

function setAppointmentStatus(id, status) {
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.token
    },
    body: JSON.stringify({
      newStatus: status
    })
  };

  return fetch(Env.backendServerUrl + `/api/v1/appointments/` + id + "/status", requestOptions)
  .then(handleResponse)
  .then(appointment => {
    return appointment
  });
}

function setAppointmentOperatorNote(id, note) {
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.token
    },
    body: JSON.stringify({
      note: note
    })
  };

  return fetch(Env.backendServerUrl + `/api/v1/appointments/` + id + "/operator-note", requestOptions)
  .then(handleResponse)
  .then(appointment => {
    return appointment
  });
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        userService.logout()
        location.reload(true);
      }
      if (response.status === 403) {
        alert("Nedostatečná práva")
        router.push("/dashboard/default")
        location.reload(false);
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

