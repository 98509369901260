<template>
  <div>
    <Breadcrumbs title="Kontakty"/>
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <!-- Firemní údaje -->
        <div class="col-md-12 col-lg-8">
          <div class="card">
            <div class="card-header">
              <h5>Interní</h5>
            </div>
            <div class="card-body">
              <table class="table">
                <tr>
                  <th>Jméno</th>
                  <th>Pozice, zodpovědnost</th>
                  <th>Telefon</th>
                  <th>E-mail</th>
                </tr>
                <tr>
                  <td>Adam Gardelka</td>
                  <td>hlavní lékař, jednatel</td>
                  <td>+420 607 732 858</td>
                  <td>adam.gardelka@ggmedical.cz</td>
                </tr>
                <tr>
                  <td>Karolina Gardelková</td>
                  <td>lékař</td>
                  <td>+420 728 945 263</td>
                  <td>karolina.gardelkova@ggmedical.cz</td>
                </tr>
                <tr>
                  <td>Jan Gula</td>
                  <td>technický ředitel</td>
                  <td>+420 728 275 662</td>
                  <td>jan.gula@ggmedical.cz</td>
                </tr>
                <tr>
                    <td>Petra Staudacherová</td>
                    <td>Staniční sestra</td>
                    <td>+420 608 200 311</td>
                    <td>petra.staudachrova@ggmedical.cz</td>
                </tr>
              </table>
            </div>
          </div>
        </div>

        <!-- Odkazy -->
        <div class="col-md-12 col-lg-8">
          <div class="card">
            <div class="card-header">
              <h5>Pobočka Barrandov</h5>
            </div>
            <div class="card-body">
              <p>
                Mandlové 1, Praha 5 - Barrandov<br />
                152 00
              </p>

              <table class="table">
                <tr>
                  <th>Jméno</th>
                  <th>Pozice, zodpovědnost</th>
                  <th>Telefon</th>
                  <th>E-mail</th>
                </tr>
                <tr>
                  <td>Martin Šimeček</td>
                  <td>PP - Správce budovy</td>
                  <td>+420 602 309 988</td>
                  <td>m.simecek@ppmas.cz</td>
                </tr>
                <tr>
                  <td>Nela Zineckerová</td>
                  <td>FINEP - účetní</td>
                  <td>+420 606 044 146</td>
                  <td>nela.zineckerova@finep.cz</td>
                </tr>
                <tr>
                    <td>Jana Hejná</td>
                    <td>FINEP - reklamace</td>
                    <td>+420 702 146 199</td>
                    <td>jana.hejna@finep.cz</td>
                </tr>
                <tr>
                  <td>Marek Měchura</td>
                  <td>FINEP - obchod</td>
                  <td>+420 605 227 029</td>
                  <td>marek.mechura@finep.cz</td>
                </tr>
                <tr>
                  <td>Tomáš Šedina</td>
                  <td>FINEP - projektant</td>
                  <td>+420 727 989 993</td>
                  <td>tomas.sedina@finep.cz</td>
                </tr>
                <tr>
                  <td>Petr Beránek</td>
                  <td>FINEP - stavba</td>
                  <td>+420 724 163 850</td>
                  <td>petr.beranek@finep.cz</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
export default {}
</script>
