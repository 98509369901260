<template xmlns="http://www.w3.org/1999/html">
  <div>
    <Breadcrumbs title="Detail účtu"/>

    <!-- Edit account modal -->
    <b-modal v-if="!loading" id="edit-account-modal" size="lg" title="Upravit účet" ok-title="Upravit" cancel-title="Zrušit" class="theme-modal"
             ok-variant="warning"
             @show="resetModal"
             @hidden="resetModal"
             @ok="editAccountOk"
    >
      <form class="theme-form" ref="accountForm" id="accountForm" @submit.stop.prevent="editAccount">
        <div class="form-group">
          <label for="username">Jméno:</label>
          <input
              type="text"
              v-model="account.firstName"
              name="firstName"
              class="form-control"
              required
              :class="{ 'is-invalid': editFormSubmitted && !account.firstName }"
          />
        </div>
        <div class="form-group">
          <label for="username">Příjmení:</label>
          <input
              type="text"
              v-model="account.lastName"
              name="lastName"
              class="form-control"
              required
              :class="{ 'is-invalid': editFormSubmitted && !account.lastName }"
          />
        </div>
        <div class="form-group">
          <label for="username">Uživatelské jméno:</label>
          <input
              type="text"
              v-model="account.username"
              name="username"
              class="form-control"
              required
              :class="{ 'is-invalid': editFormSubmitted && !account.username }"
          />
        </div>
        <div class="form-group">
          <label for="username">E-mail:</label>
          <input
              type="email"
              v-model="account.email"
              name="email"
              class="form-control"
              required
              :class="{ 'is-invalid': editFormSubmitted && !account.email }"
          />
        </div>
        <div class="form-group">
          <label for="username">Role:</label>
          <select
              v-model="account.role"
              name="role"
              class="form-control"
              required
              :class="{ 'is-invalid': editFormSubmitted && !account.role }"
          >
            <!-- TODO: make it dynamic -->
            <option value="ADMIN">Administrátor</option>
            <option value="MANAGER">Manažer</option>
            <option value="DOCTOR">Doktor</option>
            <option value="HEAD_NURSE">Hlavní sestra</option>
            <option value="NURSE">Sestra</option>
          </select>
        </div>
      </form>
    </b-modal>
    <!-- account edit modal END -->

    <!-- Change password modal -->
    <b-modal v-if="!loading" id="change-password-modal" size="lg" title="Změnit heslo" ok-title="Změnit heslo" cancel-title="Zrušit" class="theme-modal"
             ok-variant="warning"
             @show="resetModal"
             @hidden="resetModal"
             @ok="changePasswordOk"
    >
      <form class="theme-form" ref="changePasswordForm" id="changePasswordForm" @submit.stop.prevent="changePassword">
        <div class="form-group">
          <label for="username">Nové heslo:</label>
          <input
              type="password"
              v-model="newPassword"
              name="newPassword"
              class="form-control"
              required
          />
        </div>
        <div class="form-group">
          <label for="username">Nové heslo pro ověření:</label>
          <input
              type="password"
              v-model="newPasswordCheck"
              name="newPasswordCheck"
              class="form-control"
              required
          />
        </div>
      </form>
    </b-modal>

    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <!-- Accounts -->
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-6">
                  <h5>Účet <span v-if="!loading">{{ account.firstName }} {{ account.lastName }}</span></h5>
                </div>
                <div class="col-6 text-right">
                  <b-button v-b-modal.edit-account-modal variant="warning">Upravit</b-button>
                  <b-button v-b-modal.change-password-modal variant="warning">Změnit heslo</b-button>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="text-center loading" v-if="loading">
                Nahrávám ...
              </div>

              <table class="table" v-if="!loading">
                <tr>
                  <th>Jméno</th>
                  <td>{{ account.firstName }} {{ account.lastName }}</td>
                </tr>
                <tr>
                  <th>Uživatelské jméno</th>
                  <td>{{ account.username }}</td>
                </tr>
                <tr>
                  <th>E-mail</th>
                  <td>{{ account.email }}</td>
                </tr>
                <tr>
                  <th>Role</th>
                  <td>{{ account.role }}</td>
                </tr>
              </table>

            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import {userService} from "@/_services/user.service";
import {formatDateTime} from "@/_helpers";

export default {
  data() {
    return {
      id: null,
      account: null,
      loading: true,
      editFormSubmitted: false,

      newPassword: null,
      newPasswordCheck: null,
      changePasswordFormSubmitted: false,
    }
  },
  methods: {
    checkFormValidity() {
      return this.$refs.accountForm.checkValidity()
    },
    format(date) {
      return formatDateTime(date)
    },
    resetModal() {
      this.editFormSubmitted = false
      this.changePasswordFormSubmitted = false
      this.getAccount()
    },
    editAccountOk(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.editAccount()
    },
    changePasswordOk(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.changePassword()
    },
    changePassword() {
      this.changePasswordFormSubmitted = false
      if (this.newPassword != null && this.newPassword === this.newPasswordCheck) {
        userService.changePassword(this.account.id, this.newPassword).then(result => {
          this.$nextTick(() => {
            this.$bvModal.hide('change-password-modal')
          })
        }).catch(error =>
            alert("Nepodařilo se změnit heslo: " + error)
        )
      } else {
        alert("Neplatné heslo nebo se hesla neshodují")
      }
    },
    editAccount() {
      this.editFormSubmitted = true
      if (!this.checkFormValidity()) {
        return
      }

      const request = {
        firstName: this.account.firstName,
        lastName: this.account.lastName,
        username: this.account.username,
        email: this.account.email,
        role: this.account.role,
        employeeFrom: this.account.employeeFrom,
        employeeTo: this.account.employeeTo,
        bozpTo: this.account.bozpTo,
        mainSalary: this.account.mainSalary,
        secondarySalary: this.account.secondarySalary,
        workingHours: this.account.workingHours,
        branch: this.account.branch,
        birthNumber: this.account.birthNumber,
        phoneNumber: this.account.phoneNumber,
        note: this.account.note,
      }
      userService.updateAccount(this.account.id, request).then(result => {
        this.getAccount()
        this.$nextTick(() => {
          this.$bvModal.hide('edit-account-modal')
        })
      }).catch(error =>
          alert("Nepodařilo se vytvořit uživatele: " + error)
      )

    },
    getAccount() {
      userService.getAccount(this.id).then(account => {
        this.account = account
        this.loading = false
      })
    },
  },
  created() {
    this.id = this.$route.params.id
    this.getAccount()
  }
}
</script>
