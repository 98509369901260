<template xmlns="http://www.w3.org/1999/html">
  <div>
    <Breadcrumbs title="Role"/>

    <!-- Add account modal -->
    <b-modal id="add-modal" size="lg" title="Přidat roli" ok-title="Přidat" cancel-title="Zrušit" class="theme-modal"
             @show="resetModal"
             @hidden="resetModal"
             @ok="addRoleOk"
    >
      <form class="theme-form" ref="newForm" id="newForm" @submit.stop.prevent="addRole">
        <div class="form-group">
          <label for="username">Kód:</label>
          <input
              type="text"
              v-model="role.code"
              name="code"
              class="form-control"
              required
              :class="{ 'is-invalid': submitted && !role.code }"
          />
        </div>
        <div class="form-group">
          <label for="username">Název:</label>
          <input
              type="text"
              v-model="role.title"
              name="title"
              class="form-control"
              required
              :class="{ 'is-invalid': submitted && !role.title }"
          />
        </div>
        <div class="form-group">
          <label for="username">Level:</label>
          <input
              type="text"
              v-model="role.level"
              name="level"
              class="form-control"
              required
              :class="{ 'is-invalid': submitted && !role.level }"
          />
        </div>
      </form>
    </b-modal>



    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <!-- Accounts -->
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-6">
                  <h5>Role</h5>
                </div>
                <div class="col-6 text-right">
                  <b-button v-b-modal.add-modal variant="primary">Přidat</b-button>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="text-center loading" v-if="loading">
                Nahrávám ...
              </div>

              <b-row v-if="!loading">
                <b-col xl="6">
                  <b-input-group class="datatable-btn">
                    <b-form-input v-model="filter" placeholder="Vyhledat"></b-form-input>
                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''">Vyčistit</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>

                <b-col xl="6">
                  <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <div class="table-responsive datatable-vue">
                <b-table
                    show-empty
                    stacked="md"
                    :items="roles"
                    :fields="tableFields"
                    :filter="filter"
                    :current-page="currentPage"
                    :per-page="perPage"
                    @filtered="onFiltered"
                >
                  <template v-slot:cell(action)="{ item }">
                    <span><b-btn @click="detail(item.id)">Upravit</b-btn></span>
                  </template>
                </b-table>
              </div>

              <b-col md="6" class="my-1 p-0">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    class="my-0"
                ></b-pagination>
              </b-col>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import {userService} from "@/_services/user.service";
import {formatDateTime} from "@/_helpers";
import router from "@/router";

export default {
  data() {
    return {
      role: {},
      submitted: false,
      roles: [],
      loading: false,
      filter: null,

      tableFields: [
        { key: 'code', label: 'Kód', sortable: true },
        { key: 'title', label: 'Název', sortable: true },
        { key: 'level', label: 'Level', sortable: true },
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, 50],
    }
  },
  methods: {
    checkFormValidity() {
      return this.$refs.newForm.checkValidity()
    },
    format(date) {
      return formatDateTime(date)
    },
    resetModal() {
      this.submitted = false
      this.role = {}
    },
    addRoleOk(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.addRole()
    },
    addRole(bvModalEvent) {
      this.submitted = true
      if (!this.checkFormValidity()) {
        return
      }

      userService.addRole(this.role).then(result => {
        this.getRoles()
        this.$nextTick(() => {
          this.$bvModal.hide('add-modal')
        })
      }).catch( error =>
          alert("Nepodařilo se vytvořit uživatele: " + error)
      )

    },
    getRoles() {
      userService.getRoles().then(roles => {
        this.roles = roles.data
      })
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    detail(id) {
      router.push("/dashboard/account-detail/" + id)
    }
  },
  created() {
    this.getRoles()
  },
  addAccount() {

  }
}
</script>
