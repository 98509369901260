// import config from 'config';

/* eslint-disable */
import router from "@/router";
import Env from '../data/env'
import {userService} from "@/_services/user.service";

export const subscriptionService = {
      getSubscriptions,
      getActiveSubscriptions
    }
;

function getSubscriptions() {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.token
    }
  };

  return fetch(Env.backendServerUrl + `/api/v1/subscriptions`, requestOptions)
  .then(handleResponse)
  .then(registrations => {
    return registrations
  });
}

function getActiveSubscriptions() {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.token
    }
  };

  return fetch(Env.backendServerUrl + `/api/v1/subscriptions/active`, requestOptions)
  .then(handleResponse)
  .then(registrations => {
    return registrations
  });
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        userService.logout()
        location.reload(true);
      }
      if (response.status === 403) {
        alert("Nedostatečná práva")
        router.push("/dashboard/default")
        location.reload(false);
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

