import Vue from 'vue'
import Router from "vue-router";

import Body from '../components/body'

import Auth from '../auth/auth';
import Userauth from '../auth/js/index';

import CompanyOverview from '../pages/company_overview'
import Contacts from '../pages/contacts'
import RegistrationsWaiting from '../pages/registrations-waiting'
import RegistrationsDetail from '../pages/registrations-detail'
import RegistrationsAppointed from "@/pages/registrations-appointed";
import login from '../pages/login'
import RegistrationsArchive from "@/pages/registrations-archive";
import Accounts from "@/pages/accounts";
import AccountDetail from "@/pages/account-detail";
import Roles from "@/pages/roles";
import AppointmentsWaiting from "@/pages/appointments-waiting";
import AppointmentsDetail from "@/pages/appointments-detail";
import AppointmentsArchive from "@/pages/appointments-archive";
import Subscriptions from "@/pages/subscriptions";
import SubscriptionsActive from "@/pages/subscriptions-active";
import Profile from "@/pages/profile.vue";
import RegistrationStatistics from "@/pages/registration-statistics.vue";
import WebConfig from "@/pages/web-config.vue";
import Employees from "@/pages/employees.vue";
import EmployeeDetail from "@/pages/employee-detail.vue";
import Checks from "@/pages/checks.vue";

// component

Vue.use(Router)

const routes = [
  {path: '', redirect: {name: 'default'}},
  {
    path: '/auth',
    component: Auth,
    children: [
      {
        path: 'login',
        name: 'Login 1',
        component: login,
        meta: {
          title: 'Login',
        }
      }
    ]
  },
  {
    path: '/dashboard',
    component: Body,
    children: [
      {
        path: 'default',
        name: 'default',
        component: CompanyOverview,
        meta: {
          title: 'G&G - Firemní přehled',
        }
      },
      {
        path: 'contacts',
        name: 'contacts',
        component: Contacts,
        meta: {
          title: 'G&G - Kontakty',
        }
      },
      {
        path: 'profile',
        name: 'profile',
        component: Profile,
        meta: {
          title: 'G&G - Profil',
        }
      },
      {
        path: 'accounts',
        name: 'accounts',
        component: Accounts,
        meta: {
          title: 'G&G - Účty',
        }
      },
      {
        path: 'account-detail/:id',
        name: 'account-detail',
        component: AccountDetail,
        meta: {
          title: 'G&G - Detail účtu',
        }
      },
      {
        path: 'roles',
        name: 'roles',
        component: Roles,
        meta: {
          title: 'G&G - Role',
        }
      },
      {
        path: 'registrations-waiting',
        name: 'registrations-waiting',
        component: RegistrationsWaiting,
        meta: {
          title: 'G&G - Registrace',
        }
      },
      {
        path: 'registration-detail/:id',
        name: 'registration-detail',
        component: RegistrationsDetail,
        meta: {
          title: 'G&G - Detail registrace',
        },
      },
      {
        path: 'registrations-appointed',
        name: 'registrations-appointed',
        component: RegistrationsAppointed,
        meta: {
          title: 'G&G - Objednané registrace',
        }
      },
      {
        path: 'registrations-archive',
        name: 'registrations-archive',
        component: RegistrationsArchive,
        meta: {
          title: 'G&G - Archiv registrací',
        }
      },
      {
        path: 'appointments-waiting',
        name: 'appointments-waiting',
        component: AppointmentsWaiting,
        meta: {
          title: 'G&G - Objednání',
        }
      },
      {
        path: 'appointments-detail/:id',
        name: 'appointments-detail',
        component: AppointmentsDetail,
        meta: {
          title: 'G&G - Detail objednání',
        }
      },
      {
        path: 'appointments-archive',
        name: 'appointments-archive',
        component: AppointmentsArchive,
        meta: {
          title: 'G&G - Archiv objednání',
        }
      },
      {
        path: 'subscriptions',
        name: 'subscriptions',
        component: Subscriptions,
        meta: {
          title: 'G&G - Subscriptions',
        }
      },
      {
        path: 'subscriptions-active',
        name: 'subscriptions-active',
        component: SubscriptionsActive,
        meta: {
          title: 'G&G - Subscriptions aktivní',
        }
      },
      {
        path: 'web-registrations',
        name: 'web-registrations',
        component: RegistrationStatistics,
        meta: {
          title: 'G&G - Webové registrace',
        }
      },
      {
        path: 'web-config',
        name: 'web-config',
        component: WebConfig,
        meta: {
          title: 'G&G - Nastavení webu',
        }
      },
      {
        path: 'employees',
        name: 'Employees',
        component: Employees,
        meta: {
          title: 'G&G - Zaměstnanci',
        }
      },
      {
        path: 'employee/:id',
        name: 'employee',
        component: EmployeeDetail,
        meta: {
          title: 'G&G - Detail zaměstnance',
        },
      },
      {
        path: 'checks',
        name: 'Checks',
        component: Checks,
        meta: {
          title: 'G&G - Kontroly',
        }
      },
    ]
  }];

const router = new Router({
  routes,
  base: '/',
  mode: 'history',
  linkActiveClass: "active",
  scrollBehavior() {
    return {x: 0, y: 0}
  }
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  const path = ['/auth/login', '/auth/register'];
  if (path.includes(to.path) || to.path === '/callback'
      || Userauth.isAuthenticatedUser()) {
    return next();
  }
  next('/auth/login');
});

export default router
