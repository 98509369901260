<template>
    <div>
        <Breadcrumbs title="Statistiky webových registrací"/>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-12" style="text-align: right;padding-bottom: 20px">
                    <button class="btn btn-primary" v-on:click="refresh">Obnovit data</button>
                </div>

                <!-- Statistics -->
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-2 col-sm-6">
                            <div class="card">
                                <div class="card-body" style="align-content: center; text-align: center">
                                    <h5>Dnes</h5>
                                    <span class="dashboard-value">{{ statistics.today }}</span>
                                </div>
                            </div>
                        </div>

                        <!-- yesterday -->
                        <div class="col-lg-2 col-sm-6">
                            <div class="card">
                                <div class="card-body" style="align-content: center; text-align: center">
                                    <h5>Včera</h5>
                                    <span class="dashboard-value">{{ statistics.yesterday }}</span>
                                </div>
                            </div>
                        </div>

                        <!-- last 7 days -->
                        <div class="col-lg-2 col-sm-6">
                            <div class="card">
                                <div class="card-body" style="align-content: center; text-align: center">
                                    <h5>Posledních 7 dní</h5>
                                    <span class="dashboard-value">{{ statistics.last7d }}</span>
                                </div>
                            </div>
                        </div>

                        <!-- last 30 days -->
                        <div class="col-lg-2 col-sm-6">
                            <div class="card">
                                <div class="card-body" style="align-content: center; text-align: center">
                                    <h5>Posledních 30 dní</h5>
                                    <span class="dashboard-value">{{ statistics.last30d }}</span>
                                </div>
                            </div>
                        </div>

                        <!-- last month -->
                        <div class="col-lg-2 col-sm-6">
                            <div class="card">
                                <div class="card-body" style="align-content: center; text-align: center">
                                    <h5>Minulý měsíc</h5>
                                    <span class="dashboard-value">{{ statistics.previousMonth }}</span>
                                </div>
                            </div>
                        </div>

                        <!-- last 365 days -->
                        <div class="col-lg-2 col-sm-6">
                            <div class="card">
                                <div class="card-body" style="align-content: center; text-align: center">
                                    <h5>Za 365 dní</h5>
                                    <span class="dashboard-value">{{ statistics.lastYear }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <!-- New registrations -->
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h5>Zdroj registrací</h5>
                        </div>

                        <div class="card-body">

                            <form id="sourceStatisticsFrom" @submit.stop.prevent="refreshSourceStatistics">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="username">Od:</label>
                                            <input type="date" class="form-control" v-model="sourceStatisticsFrom"/>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="username">Do:</label>
                                            <input type="date" class="form-control" v-model="sourceStatisticsTo"/>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <input type="submit" class="btn btn-primary" value="Aktualizovat" />
                                </div>
                            </form>


                            <div class="text-center loading" v-if="loadingSourceStats">
                                Nahrávám ...
                            </div>

                            <div class="row" v-if="!loadingSourceStats">
                                <div class="col-lg-4 col-12">
                                    <Doughnut :data="chartData" :options="barOptions"/>
                                </div>
                                <div class="col-lg-8 col-12">
                                    <Bar :data="chartData" :options="barOptions"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import {registrationService} from "@/_services/registrations.service";
import {formatDateTime} from "@/_helpers";
import {Bar, Doughnut} from 'vue-chartjs'
import {ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip} from 'chart.js'


ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement)

export default {
    components: {Bar, Doughnut},
    methods: {
        formatDate(date) {
            return formatDateTime(date)
        },
        refresh() {
            this.loading = true
            this.loadingSourceStats = true
            this.getCountStatistics()
            this.getSourceStatistics()
        },
        getCountStatistics() {
            registrationService.getCountStatistics().then(statistics => {
                this.statistics = statistics
                this.loading = false
            })
        },
        getSourceStatistics() {
            let from = this.$data.sourceStatisticsFrom;
            if (from === undefined) {
                from = this.getDefaultFrom()
            }
            let to = this.$data.sourceStatisticsTo;
            if (to === undefined) {
                to = this.getDefaultTo()
            }
            registrationService.getSourceStatistics(from, to).then(statistics => {
                this.sourceStatistics = statistics
                this.loadingSourceStats = false
            })
        },
        refreshSourceStatistics() {
            this.loadingSourceStats = true
            this.getSourceStatistics()
        },
        cancelAutoUpdate() {
            clearInterval(this.timer);
        },
        getDefaultFrom() {
            var date = new Date()
            date.setMonth(date.getMonth() - 1)
            return date.toJSON().slice(0, 10);
        },
        getDefaultTo() {
            return new Date().toJSON().slice(0, 10);
        },
    },
    data() {
        return {
            loading: false,
            loadingStats: false,
            statistics: {
                today: "-",
                yesterday: "-",
                last7d: "-",
                last30d: "-",
                lastYear: "-",
                previousMonth: "-",
            },
            loadingSourceStats: false,
            sourceStatistics: {
                search: "-",
                facebook: "-",
                instagram: "-",
                billboard: "-",
                flyer: "-",
                byReference: "-",
                none: "-",
            },
            timer: '',
            registrationsNew: [],
            registrationsInProgress: [],
            sourceStatisticsFrom: this.getDefaultFrom(),
            sourceStatisticsTo: this.getDefaultTo(),
        }
    },
    created() {
        this.getCountStatistics()
        this.getSourceStatistics()
    },
    beforeUnmount() {
        this.cancelAutoUpdate();
    },
    computed: {
        chartData() {
            return {
                labels: ['Instagram', 'Facebook', 'Letáček', 'Vyhledávání', 'Billboard', 'Osobní doporučení', 'Neznámé'],
                datasets: [{
                    label: "Zdroje registrací",
                    data: [
                        this.$data.sourceStatistics.instagram,
                        this.$data.sourceStatistics.facebook,
                        this.$data.sourceStatistics.flyer,
                        this.$data.sourceStatistics.search,
                        this.$data.sourceStatistics.billboard,
                        this.$data.sourceStatistics.byReference,
                        this.$data.sourceStatistics.none,
                    ],
                    backgroundColor: [
                        'rgb(178,108,0)',
                        'rgb(54, 162, 235)',
                        'rgb(157,0,30)',
                        'rgb(255,205,0)',
                        'rgb(0,42,255)',
                        'rgb(255,0,251)',
                        'rgb(134,134,134)',
                    ],
                }]
            }
        },
        barOptions() {
            return {
                responsive: true
            }
        },
    }
}
</script>
