import EventEmitter from 'events';

class Auth extends EventEmitter {

    isAuthenticatedUser() {        
        return (
            new Date(Date.now()) < new Date(localStorage.getItem("tokenExpiration")) &&
            localStorage.getItem("token") != null
        );
    }
}

export default new Auth();

