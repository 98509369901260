<template xmlns="http://www.w3.org/1999/html">
  <div>
    <Breadcrumbs title="Kontroly"/>

    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <!-- Accounts -->
        <div class="col-12">
          Pracuji na tom...
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  methods: {}
}
</script>
