export function formatDateTime(date) {
  const jsDate = new Date(date)
  const minutes = (jsDate.getUTCMinutes() < 10? '0' : '') + jsDate.getUTCMinutes();
  return jsDate.getUTCDate() + "." + (jsDate.getUTCMonth()+1) + "." + jsDate.getUTCFullYear() + " " + jsDate.getHours() + ":" + minutes
}

export function formatDate(date) {
  const jsDate = new Date(date)
  const year = jsDate.getUTCFullYear();

  if (year < 2000) {
    return "-";
  }
  return jsDate.getUTCDate() + "." + (jsDate.getUTCMonth()+1) + "." + jsDate.getUTCFullYear()
}

export function isBeforeNow(date) {
  const tmpDate = Date.parse(date)
  if (tmpDate) {
    const jsDate = Date.now()
    return tmpDate < jsDate;
  }
  return false
}
