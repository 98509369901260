<template>
  <div>
    <Breadcrumbs title="Detail registrace"/>
    <!-- Container-fluid starts-->
    <div class="container-fluid">

      <div class="row">

        <div class="col-12" style="text-align: right;padding-bottom: 20px">
          <div v-if="loading === false && registration.status === 'NEW'" role="group" class="btn-group">
            <button class="btn btn-primary" v-on:click="process">Zprocesovat</button>
          </div>
          <div v-if="loading === false && registration.status === 'IN_PROGRESS'" role="group" class="btn-group">
            <button class="btn btn-dark-gradien" v-on:click="returnRegistration">Vrátit</button>
            <button class="btn btn-danger" v-on:click="canceled">Zamítnout</button>
            <button class="btn btn-primary" v-on:click="appointed">Objednáno</button>
          </div>
          <div v-if="loading === false && registration.status === 'APPOINTED'" role="group" class="btn-group">
            <button class="btn btn-dark-gradien" v-on:click="canceled">Zrušit registraci</button>
            <button class="btn btn-primary" v-on:click="completed">Dokončeno</button>
          </div>
          <div v-if="loading === false && (registration.status === 'CANCELED' || registration.status === 'COMPLETED')" role="group" class="btn-group">
            <button class="btn btn-warning" v-on:click="restoreRegistration">Obnovit registraci</button>
          </div>
        </div>

        <!-- Detail registrace -->
        <div class="col-md-6 col-xs-12">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-md-8"><h5>Registrace <span v-if="registration">{{ registration.firstName }} {{ registration.lastName }}</span></h5> <span class="registration-status" v-if="registration">[{{ registration.status }}]</span>
                </div>
                <div class="col-md-4" style="text-align: right">
                  <button class="btn btn-primary" v-on:click="getRegistration">Obnovit data</button>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="text-center loading" v-if="loading">
                Nahrávám ...
              </div>
              <table class="table" v-if="!loading">
                <tr>
                  <th>Jméno</th>
                  <td>{{ registration.firstName }}</td>
                </tr>
                <tr>
                  <th>Příjmení</th>
                  <td>{{ registration.lastName }}</td>
                </tr>
                <tr>
                    <th>Pojišťovna</th>
                    <td>{{ registration.insurance || '-'  }}</td>
                </tr>
                <tr>
                  <th>Čas registrace</th>
                  <td>{{ formatDate(registration.createdAt) }}</td>
                </tr>
                <tr>
                  <th>Rodné číslo</th>
                  <td>{{ registration.birthNumber || '-' }}</td>
                </tr>
                <tr>
                  <th>E-mail</th>
                  <td>{{ registration.email }}</td>
                </tr>
                <tr>
                  <th>Telefon</th>
                  <td>{{ registration.phone }}</td>
                </tr>
                <tr v-if="registration.howFound != null">
                    <th>Marketing</th>
                    <td>{{ registration.howFound || '-' }}</td>
                </tr>
                <tr>
                  <th colspan="2">Poznámka pacienta:</th>
                </tr>
                <tr>
                  <td colspan="2">
                    {{ registration.note || '-' }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <!-- KONEC detailu registrace -->

        <!-- Poznámky operátora -->
        <div class="col-md-6 col-xs-12">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-md-8"><h5>Poznámky operátora</h5></div>
                <div class="col-md-4" style="text-align: right">
                  <button class="btn btn-success" v-on:click="saveNote" v-if="noteChanged">Uložit</button>
                </div>
                <span v-if="noteChanged" style="padding-left: 15px; color: #a61c00">Poznámka byla upravena. Nezapomeťe ji uložit!</span>
                <span v-if="!noteChanged && noteSaved" style="padding-left: 15px; color: #00a603">Poznámka uložena</span>
                <span v-if="!noteChanged" style="padding-left: 15px"></span>
              </div>
            </div>
            <div class="card-body">
              <textarea v-if="!loading" v-on:input="operatorNoteChanged" v-model="registration.operatorNote" name="operatorNote" rows="20" style="width: 100%; font-size: 18px;">
              </textarea>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import {registrationService} from "@/_services/registrations.service";
import router from "@/router";
import {formatDateTime} from "@/_helpers";

export default {
  methods: {
    formatDate(date) {
      return formatDateTime(date)
    },
    getRegistration() {
      if (this.noteChanged) {
        if (!confirm("Poznámka byla upravena a nebyla uložena. Opravdu ji chcete zahodit?")) {
          return
        }
      }
      this.noteSaved = false
      this.noteChanged = false
      this.loading = true
      registrationService.getRegistrationDetail(this.id).then(registration => {
            this.registration = registration
            this.loading = false
          }
      )
    },
    saveNote() {
      registrationService.setRegistrationOperatorNote(this.id, this.registration.operatorNote).then(() => {
        this.noteChanged = false
        this.noteSaved = true
      })
    },
    operatorNoteChanged() {
      this.noteSaved = false
      this.noteChanged = true
    },
    process() {
      registrationService.setRegistrationStatus(this.id, "IN_PROGRESS").then(() =>
          this.getRegistration()
      ).catch(error => {
        alert("Není možné zprocesovat registraci: " + error)
      })
    },
    returnRegistration() {
      if (!this.noteChanged)
      registrationService.setRegistrationStatus(this.id, "NEW").then(() =>
          router.push("/dashboard/registrations-waiting")
      )
    },
    appointed() {
      if (this.registration.operatorNote != null && this.registration.operatorNote !== "" && !this.noteChanged) {
        registrationService.setRegistrationStatus(this.id, "APPOINTED").then(() =>
            router.push("/dashboard/registrations-waiting")
        )
      } else {
        alert("Objednaná registrace musí mít vyplněnou poznámku (minimálně datum a čas vstupní prohlídky)")
      }
    },
    completed() {
        registrationService.setRegistrationStatus(this.id, "COMPLETED").then(() =>
            router.push("/dashboard/registrations-appointed")
        )
    },
    canceled() {
      if (confirm("Opravdu chete zrušit registraci? Nebudete se k ní už moci vrátit!")) {
        if (this.registration.operatorNote != null && this.registration.operatorNote !== "" && !this.noteChanged) {
          registrationService.setRegistrationStatus(this.id, "CANCELED").then(() =>
              router.push("/dashboard/registrations-waiting")
          ).catch(error => alert(error))
        } else {
          alert("Nelze zrušit registraci bez komentáře.")
        }
      }
    },
    restoreRegistration() {
      if(confirm("Opravdu chcete obnovit registraci? Stane se z ní nová a celý proces bude nutné absolvovat znovu!")) {
        registrationService.setRegistrationStatus(this.id, "NEW").then(() =>
            //router.push("/dashboard/registrations-waiting")
            this.getRegistration()
        )
      }
    }
  },
  data() {
    return {
      id: '',
      loading: false,
      timer: '',
      registration: null,
      noteChanged: false,
      noteSaved: false,
    }
  },
  created() {
    this.id = this.$route.params.id
    this.getRegistration()
  }
}
</script>
