<template>
  <div>
    <Breadcrumbs title="Registrace"/>
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-12" style="text-align: right;padding-bottom: 20px">
          <button class="btn btn-primary" v-on:click="refresh">Obnovit data</button>
        </div>

        <!-- Statistics -->
          <div class="col-12">
              <div class="row">
                  <div class="col-lg-2 col-sm-6">
                      <div class="card">
                          <div class="card-body" style="align-content: center; text-align: center">
                              <h5>Dnes</h5>
                              <span class="dashboard-value">{{ statistics.today }}</span>
                          </div>
                      </div>
                  </div>

                  <!-- yesterday -->
                  <div class="col-lg-2 col-sm-6">
                      <div class="card">
                          <div class="card-body" style="align-content: center; text-align: center">
                              <h5>Včera</h5>
                              <span class="dashboard-value">{{ statistics.yesterday }}</span>
                          </div>
                      </div>
                  </div>

                  <!-- last 7 days -->
                  <div class="col-lg-2 col-sm-6">
                      <div class="card">
                          <div class="card-body" style="align-content: center; text-align: center">
                              <h5>Posledních 7 dní</h5>
                              <span class="dashboard-value">{{ statistics.last7d }}</span>
                          </div>
                      </div>
                  </div>

                  <!-- last 30 days -->
                  <div class="col-lg-2 col-sm-6">
                      <div class="card">
                          <div class="card-body" style="align-content: center; text-align: center">
                              <h5>Posledních 30 dní</h5>
                              <span class="dashboard-value">{{ statistics.last30d }}</span>
                          </div>
                      </div>
                  </div>

                  <!-- last month -->
                  <div class="col-lg-2 col-sm-6">
                      <div class="card">
                          <div class="card-body" style="align-content: center; text-align: center">
                              <h5>Minulý měsíc</h5>
                              <span class="dashboard-value">{{ statistics.previousMonth }}</span>
                          </div>
                      </div>
                  </div>

                  <!-- last 365 days -->
                  <div class="col-lg-2 col-sm-6">
                      <div class="card">
                          <div class="card-body" style="align-content: center; text-align: center">
                              <h5>Za 365 dní</h5>
                              <span class="dashboard-value">{{ statistics.lastYear }}</span>
                          </div>
                      </div>
                  </div>
              </div>

          </div>



        <!-- New registrations -->
        <div class="col-md-12 col-lg-6">
          <div class="card">
            <div class="card-header">
              <h5>Čekající registrace</h5>
            </div>

            <div class="card-body">
              <div class="text-center loading" v-if="loading">
                Nahrávám ...
              </div>
              <table class="table" v-if="!loading">
                <tr>
                  <th>Jméno</th>
                  <th>Čas registrace</th>
                  <th>Komentář</th>
                  <th>Akce</th>
                </tr>
                <tr v-for="registration in registrationsNew">
                  <td>{{ registration.firstName }} {{ registration.lastName }}</td>
                  <td>{{ formatDate(registration.createdAt) }}</td>
                  <td>
                    <feather type="message-circle" v-if="registration.operatorNote != null" :id="'operator-note-popover-' + registration.id" />
                    <b-popover :target="'operator-note-popover-' + registration.id" placement="left" triggers="hover">
                      <p>{{ registration.operatorNote }}</p>
                    </b-popover>
                  </td>
                  <td>
                    <button v-if="registration.status === 'NEW'" v-on:click="processRegistration(registration.id)" class="btn btn-primary btn-sm">Procesovat</button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <!-- END New registrations -->

        <!-- InProgress registrations -->
        <div class="col-md-12 col-lg-6">
          <div class="card">
            <div class="card-header">
              <h5>Registrace v procesu</h5>
            </div>

            <div class="card-body">
              <div class="text-center loading" v-if="loading">
                Nahrávám ...
              </div>
              <table class="table" v-if="!loading">
                <tr>
                  <th>Jméno</th>
                  <th>Čas registrace</th>
                  <th>Komentář</th>
                  <th>Akce</th>
                </tr>
                <tr v-for="registration in registrationsInProgress">
                  <td>{{ registration.firstName }} {{ registration.lastName }}</td>
                  <td>{{ formatDate(registration.createdAt) }}</td>
                  <td>
                    <feather type="message-circle" v-if="registration.operatorNote != null" :id="'operator-note-popover-process-' + registration.id" />
                    <b-popover :target="'operator-note-popover-process-' + registration.id" placement="left" triggers="hover">
                      <p>{{ registration.operatorNote }}</p>
                    </b-popover>
                  </td>
                  <td>
                    <button v-on:click="continueRegistration(registration.id)" class="btn btn-warning btn-sm">Pokračovat</button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <!-- END InProgress registrations -->

      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import {registrationService} from "@/_services/registrations.service";
import router from "@/router";
import {formatDateTime} from "@/_helpers";

export default {
  methods: {
    formatDate(date) {
      return formatDateTime(date)
    },
    refresh() {
      this.getRegistrations()
      this.getStatistics()
    },
    getRegistrations() {
      this.loading = true
      this.registrationsNew = []
      this.registrationsInProgress = []
      registrationService.getRegistrations("new,in_progress").then(registrations => {
            registrations.data.forEach((registration, index) => {
              if (registration.status === "NEW") {
                this.registrationsNew.push(registration)
              }
              if (registration.status === "IN_PROGRESS") {
                this.registrationsInProgress.push(registration)
              }
            })
            this.loading = false
          }
      )
    },
    getStatistics() {
        registrationService.getCountStatistics().then(statistics => {
            this.statistics = statistics
        })
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
    continueRegistration(id) {
      router.push("/dashboard/registration-detail/" + id)
    },
    processRegistration(id) {
      registrationService.setRegistrationStatus(id, "IN_PROGRESS").then(() =>
          router.push("/dashboard/registration-detail/" + id)
      ).catch(error => {
        alert("Není možné zprocesovat registraci: " + error)
        this.getRegistrations()
      })
    }
  },
  data() {
    return {
      loading: false,
      loadingStats: false, statistics: {
          today: "-",
            yesterday: "-",
            last7d: "-",
            last30d: "-",
            lastYear: "-",
            previousMonth: "-",
        },
      timer: '',
      registrationsNew: [],
      registrationsInProgress: []
    }
  },
  created() {
    this.getRegistrations()
    this.getStatistics()
    this.timer = setInterval(this.getRegistrations, 300000);
  },
  beforeUnmount() {
    this.cancelAutoUpdate();
  }
}
</script>
