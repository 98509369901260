<template>
  <div>
    <Breadcrumbs title="G&G Medical"/>
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <!-- Firemní údaje -->
        <div class="col-md-6 col-xs-12">
          <div class="card">
            <div class="card-header">
              <h5>Firemní údaje</h5>
            </div>
            <div class="card-body">
              <table class="table">
                <tr>
                  <th>Brand</th>
                  <td>G&G Medical</td>
                <tr/>
                <tr>
                  <th>SRO</th>
                  <td>GG Medical Clinic s.r.o.</td>
                <tr/>
                <tr>
                  <th>IČO</th>
                  <td>17586348</td>
                <tr/>
                <tr>
                  <th>DIČ</th>
                  <td>CZ17586348</td>
                <tr/>
                <tr>
                  <th>Adresa</th>
                  <td>Náměstí Olgy Scheinpflugové 1280/1, Hlubočepy, 152 00, Praha 5</td>
                <tr/>
                <tr>
                  <th>Telefon</th>
                  <td>228 222 315</td>
                <tr/>
                <tr>
                  <th>E-mail</th>
                  <td>info@ggmedical.cz</td>
                </tr>
                <tr>
                    <th>IČP</th>
                    <th>05863001</th>
                </tr>
                <tr>
                    <th>IČZ</th>
                    <th>05863000</th>
                </tr>
              </table>

            </div>
          </div>
        </div>

        <!-- Odkazy -->
        <div class="col-md-6 col-xs-12">
          <div class="card">
            <div class="card-header">
              <h5>Užitečné odkazy</h5>
            </div>
            <div class="card-body">
              <table class="table table-hover">
                <tr>
                  <th>Popis</th>
                  <th>Odkaz</th>
                  <th>Link</th>
                </tr>
                <tr>
                  <td>Web</td>
                  <td>https://www.ggmedical.cz</td>
                  <td><a href="https://ggmedical.cz" target="_blank"><i class="fa fa-globe"></i> </a></td>
                </tr>
                <tr>
                  <td>Backoffice</td>
                  <td>https://bo.ggmedical.cz</td>
                  <td><a href="https://bo.ggmedical.cz" target="_blank"><i class="fa fa-globe"></i> </a></td>
                </tr>
                <tr>
                    <td>Medistar</td>
                    <td>https://ais.cgm-medistar.cz/a3cz/</td>
                    <td><a href="https://ais.cgm-medistar.cz/a3cz/" target="_blank"><i class="fa fa-globe"></i> </a></td>
                </tr>
                <tr>
                  <td>3CX</td>
                  <td>https://1019.3cx.cloud</td>
                  <td><a href="https://1019.3cx.cloud" target="_blank"><i class="fa fa-globe"></i> </a></td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <!-- odkazy konec -->

        <!-- bankovní účty -->
        <div class="col-md-6 col-xs-12">
          <div class="card">
            <div class="card-header">
              <h5>Bankovní účty</h5>
            </div>
            <div class="card-body">
              <table class="table">
                <tr>
                  <th>Číslo účtu</th>
                  <td>6259499389/0800</td>
                <tr/>
                <tr>
                  <th>Název účtu</th>
                  <td>GG Medical Clinic s.r.o.</td>
                <tr/>
                <tr>
                  <th>Banka</th>
                  <td>Česká spořitelna</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <!-- bankovní účty konec -->

      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
export default {}
</script>
