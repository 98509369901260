<template>
  <div>
    <!-- page-wrapper Start-->
    <div class="container-fluid p-0">
      <div class="row m-0">
        <div class="col-12 p-0">
          <div class="login-card">
            <div>
              <div>
                <a class="logo">
                  <img
                    class="img-fluid for-light"
                    src="../assets/images/logo/logo_small.png"
                    alt="looginpage"
                  />
                  <img
                    class="img-fluid for-dark"
                    src="../assets/images/logo/logo_dark.png"
                    alt="looginpage"
                  />
                </a>
              </div>
              <div class="login-main">
                <b-card>
<!--                      <template #title>-->
<!--                        <img src="../assets/images/login/jwt.svg" />-->
<!--                        <span>JWT</span>-->
<!--                      </template>-->
                      <b-card-text>
<!--                        <div class="alert alert-danger">-->
<!--                          Username: test<br />-->
<!--                          Password: test-->
<!--                        </div>-->
                        <form class="theme-form" @submit.prevent="handleSubmit">
                          <div class="form-group">
                            <label for="username">Uživatelské jméno:</label>
                            <input
                              type="text"
                              v-model="username"
                              name="username"
                              class="form-control"
                              :class="{ 'is-invalid': submitted && !username }"
                            />
                            <div
                              v-show="submitted && !username"
                              class="invalid-feedback"
                            >
                              Uživatelské jméno je povinné
                            </div>
                          </div>
                          <div class="form-group">
                            <label htmlFor="password">Heslo:</label>
                            <input
                              type="password"
                              v-model="password"
                              name="password"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && !password,
                              }"
                            />
                            <div
                              v-show="submitted && !password"
                              class="invalid-feedback"
                            >
                              Heslo je povinné
                            </div>
                          </div>
                          <div class="form-group mt-3 mb-0">
                            <button
                              class="btn btn-primary btn-block"
                              :disabled="loggingIn"
                            >
                              Přihlásit
                            </button>
                          </div>
                        </form>
                        <div v-if="error" class="error-text">
                          <p v-if="isUserOrPasswordError">Špatné jméno nebo heslo</p>
                          <p v-if="!isUserOrPasswordError">Nepodařilo se kontaktovat server</p>
                        </div>
                      </b-card-text>
                </b-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- latest jquery-->
  </div>
</template>

<script>
  export default {
    name: 'login',
    data() {
      return {
        type: 'password',
        username: '',
        password: '',
        submitted: false,
      };
    },
    computed: {
      // JWT authentication
      loggingIn() {
        return this.$store.state.authentication.status.loggingIn;
      },
      error() {
        return this.$store.state.authentication.error
      },
      isUserOrPasswordError() {
        return this.$store.state.authentication.error === "WRONG_USER_OR_PASSWORD"
      }
    },
    created() {
      // reset login status for JWT
      this.$store.dispatch('authentication/logout');
    },
    methods: {
      // JWT authentication
      handleSubmit() {
        this.submitted = true;
        const { username, password } = this;
        const { dispatch } = this.$store;
        if (username && password) {
          dispatch('authentication/login', { username, password });
        }
      },
    },
  };
</script>
