// import config from 'config';

/* eslint-disable */
import router from "@/router";
import Env from '../data/env'
import {userService} from "@/_services/user.service";

export const registrationService = {
  getRegistrations,
  getRegistrationDetail,
  setRegistrationStatus,
  setRegistrationOperatorNote,
  getCountStatistics,
  getSourceStatistics
};

function getRegistrations(statuses) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.token
    }
  };

  return fetch(Env.backendServerUrl + `/api/v1/registrations?status=` + statuses, requestOptions)
  .then(handleResponse)
  .then(registrations => {
    return registrations
  });
}

function getRegistrationDetail(id) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.token
    }
  };

  return fetch(Env.backendServerUrl + `/api/v1/registrations/` + id, requestOptions)
  .then(handleResponse)
  .then(registration => {
    return registration
  });
}

function getCountStatistics() {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.token
    }
  };

  return fetch(Env.backendServerUrl + `/api/v1/statistics/registrations`, requestOptions)
      .then(handleResponse)
      .then(statistics => {
        return statistics
      });
}

function getSourceStatistics(from, to) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.token
    }
  };

  return fetch(Env.backendServerUrl + `/api/v1/statistics/registrations/source?from=` + from + `T00:00:00Z&to=` + to + `T23:59:59Z`, requestOptions)
      .then(handleResponse)
      .then(statistics => {
        return statistics
      });
}

function setRegistrationStatus(id, status) {
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.token
    },
    body: JSON.stringify({
      newStatus: status
    })
  };

  return fetch(Env.backendServerUrl + `/api/v1/registrations/` + id + "/status", requestOptions)
  .then(handleResponse)
  .then(registration => {
    return registration
  });
}

function setRegistrationOperatorNote(id, note) {
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.token
    },
    body: JSON.stringify({
      note: note
    })
  };

  return fetch(Env.backendServerUrl + `/api/v1/registrations/` + id + "/operator-note", requestOptions)
  .then(handleResponse)
  .then(registration => {
    return registration
  });
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        userService.logout()
        location.reload(true);
      }
      if (response.status === 403) {
        alert("Nedostatečná práva")
        router.push("/dashboard/default")
        location.reload(false);
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

